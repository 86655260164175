import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Stack } from "@mui/material";

import "../view.css";
import { MdTopic } from "react-icons/md";
import StateContext from "../../contexts/ContextProvider";

const ViewNotification = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/notification");
  };
    
  const { viewData } = useContext(StateContext);
 const [title, setTitle] = useState(viewData.title);
 const [body, setBody] = useState(viewData.body);
 const [topic,setTopic]=useState(viewData.topic);
 
 
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  
    <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
       View Notification
    </h4>
    <div class="flex space-x-2 justify-end">
      <button
        type="button"
        class="backButton"
        style={{ width: "8%" }}
        onClick={() => mainPage()}
      >
        Back
      </button>

      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="title"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Title
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"

     
              name="title"
              value={title}
             
              
              placeholder="Enter Title"
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="body"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Body
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="body"
              value={body}
            
              
              placeholder="Enter Body"
            />
          </div>
  
          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 2 }}
          >
            <label
              for="topic"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Topic
            </label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="topic"
              value={topic}
             
            >
              <option>-- Select Topic --</option>
          
                <option value="USER">USER</option>
                <option value="MERCHANT">MERCHANT</option>
                <option value="DISTRIBUTOR">DISTRIBUTOR</option>
                <option value="EMPLOYEE">EMPLOYEE</option> 
            </select>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ViewNotification