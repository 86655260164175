import React, { useEffect, useState, useContext } from 'react';
import { contextMenuItems, merchantTypeGrid, merchantTypeData } from './config/MerchantTypeConfig';
import { AiFillDelete } from 'react-icons/ai';
import { BsFillPencilFill } from 'react-icons/bs';
import '../css/view.css';
import { Header } from '../../components';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import StateContext from '../../contexts/ContextProvider';
import DeleteMerchantTypeModal from './DeleteMerchantTypeModal';
import { setValue } from '@syncfusion/ej2/base';
import { Button } from "@material-ui/core";
import {
	FirstPageOutlined,
	LastPageOutlined,
	NavigateBefore,
	NavigateNext,
  } from "@material-ui/icons";
import axios from "axios";
import {
	AppBar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	makeStyles,
  } from "@material-ui/core";
  import { BiSearch } from "react-icons/bi";

   
  
  const useStyles = makeStyles((theme) => ({
	table: {
	  minWidth: 650,
	  borderCollapse: "collapse", 
	},
	cell: {
	  padding: "8px 0px 8px 16px", 
	  textAlign: "start",
	  // border: "1px solid " + theme.palette.divider,
	},
	cellRow: {
	  // border: "1px solid " + theme.palette.divider,
	  padding: "15px", 
	  textAlign: "start",
	},
  }));

const MerchantType = () => {
	const [ tDatas, setTDatas ] = useState([]);
	const classes = useStyles();
	const navigate = useNavigate();
	const { setData } = useContext(StateContext);
	const [loading, setLoading] = useState(false);
	const { setValue } = useContext(StateContext);
	const { alertData } = useContext(StateContext);
	const { value } = useContext(StateContext);
	const [searchQuery, setSearchQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(10);


	const fetchData = async() => {
		try {
			await axios.get('https://api.orbitmart.co.in/api/v1/merchant/Type')
				.then((response) => {
					setTDatas(response.data.data);
					console.log(response.data.data)
				})
				.catch((error) => {
					// Handle error
					console.error('Error fetching data:', error);
				});
		} catch (error) {
			// Handle any synchronous errors
			console.error('Error in fetchData:', error);
		}
	};
	

	useEffect(() => {
		fetchData();
	}, []);

	if (value == 200) {
		fetchData();
		setValue(289);
	}

	console.log(tDatas);
	const itemId = tDatas.data;
	console.log(itemId);

	function gridAction(itemId) {
		return (
			<div className="flex items-center gap-2">
				<h1
					style={{ color: '#8be78b', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<Link style={{ marginRight: 'auto' }} to="/merchantType/edit">
						<BsFillPencilFill onClick={() => itemEdit(itemId)} />
					</Link>
				</h1>

				<h1
					style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<DeleteMerchantTypeModal itemId={itemId._id} />
				</h1>
			</div>
		);
	}

	const itemEdit = (itemId) => {
		const editId = itemId._id;
		console.log(editId);
		setData(itemId);
	};

	const gridSrno = (itemId) => {
		var sr = itemId.index;
		sr++;
		return sr;
	};

	const addPage = () => {
		navigate('/merchantType/add');
	};

	const selectionsettings = { persistSelection: true };
	const toolbarOptions = [ 'Search' ];

	const editing = { allowDeleting: true };

	function gridImage(itemId) {
		// return <img className="rounded-xl h-20 md:ml-3" src={itemId.image} alt="order-item" />;
		return <img className="rounded-xl h-20" src={itemId.image} alt="order-item" />;
	}
	const handleChangePage = (event, newPage) => {
		if (newPage === 0) {
		 setCurrentPage(0);
	   } else if (newPage === Math.ceil(tDatas?.length / pageSize) - 1) {
		 setCurrentPage(Math.ceil(tDatas?.length / pageSize) - 1);
	   } else {
		 setCurrentPage(newPage);
	   }
	  };
	  
	   const handleChangeRowsPerPage = (event) => {
	   setPageSize(parseInt(event.target.value, 5));
	   setCurrentPage(0);
	  };
	const CustomPaginationActions = (props) => {
    
		console.log('Custom', props)
		const { count, page, rowsPerPage, onPageChange } = props;
		// const handleFirstPageButtonClick = (event) => {
		//   setLoading(true);
		//   onPageChange(event, 0);
		//   setTimeout(() => {
		//     setLoading(false);
		//   }, 500);
		// };
		 const handleFirstPageButtonClick = (event) => {
		  onPageChange(event, 0);
		};
	  
		// const handleLastPageButtonClick = (event) => {
		//   setLoading(true);
		  // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		//   setTimeout(() => {
		//     setLoading(false);
		//   }, 500);
		// };
		 const handleLastPageButtonClick = (event) => {
		  onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		};
	  
		const handleNextButtonClick = (event) => {
		  setLoading(true);
		  onPageChange(event, page + 1);
		  setTimeout(() => {
			setLoading(false);
		  }, 500);
		};
	  
		const handleBackButtonClick = (event) => {
		  setLoading(true);
		  onPageChange(event, page - 1);
		  // setTimeout(() => {
		  //   setLoading(false);
		  // }, 500);
		};
	  
		return (
			<div
			style={{
			  display: "flex",
			  justifyContent: "space-between",
			  alignItems: "center",
			}}
		  >
			<Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
			  <FirstPageOutlined />
			</Button>
			<Button onClick={handleBackButtonClick} disabled={page === 0}>
			  <NavigateBefore />
			</Button>
			<span className="myPage">{currentPage + 1}</span>
			<Button
			  onClick={handleNextButtonClick}
			  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
			>
			  <NavigateNext />
			</Button>
			<Button
			  onClick={handleLastPageButtonClick}
			  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
			>
			  <LastPageOutlined />
			</Button>
		  </div>
		);
	  };

	  const orderFilterData = tDatas?.filter(item => 
		item?.merchantType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item?.domainType.toLowerCase().includes(searchQuery.toLowerCase())
		// item?.distributorId?.firmName.toLowerCase().includes(searchQuery.toLowerCase())
	  );
	const paginatedData = orderFilterData.slice(
		currentPage * pageSize,
		currentPage * pageSize + pageSize
	  );

	  const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setCurrentPage(0); // Reset to the first page whenever a new search is made
	  };


	return (
		<div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
			<div className="flex space-x-2 m-4 justify-end">
			  <button onClick={() => addPage()} className="addBtn">
				New Record
			  </button>
			</div>
		  {/* )} */}
		  <div
			style={{
			  display: "flex",
			  justifyContent: "space-between",
			  alignItems: "center",
			  margin: "0 0 20px 0",
			  padding: "8px",
			  borderRadius: "20px",
			  background: "rgb(0, 60, 126)",
			  boxShadow:
				"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
			}}
		  >
			<h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
			Merchant Type
			</h1>
			<span
			  className="flex space-x-2 m-4 justify-end"
			  style={{ background: "white", borderRadius: "6px" }}
			>
			  <input
				style={{
				  padding: "4px 10px",
				  outline: "none",
				  borderRadius: "6px 0 0 6px",
				  color: "black",
				}}
				type="text"
				placeholder="Search..."
				value={searchQuery}
				onChange={handleSearchChange}
			  />
			  <BiSearch
				style={{
				  fontSize: "25px",
				  color: "gray",
				  cursor: "pointer",
				  margin: "auto",
				  paddingRight: "5px",
				}}
			  />{" "}
			</span>
		  </div>
		  {console.log(tDatas.data, "tDatas")}
		  <TableContainer className={tDatas.length > 0 ? "table-container" : ""}>
			<Table>
			  <TableHead>
				<TableRow>
				  <TableCell className={classes.cellRow}>Sr No</TableCell>
				  <TableCell className={classes.cellRow}>Merchant Type Image</TableCell>
				  <TableCell className={classes.cellRow}>Merchant Type</TableCell>
				  <TableCell className={classes.cellRow}>Domain</TableCell>
				  <TableCell className={classes.cellRow} style={{textAlign: 'start'}}>Action</TableCell>
				</TableRow>
			  </TableHead>
			  <TableBody>
				{paginatedData.length > 0 ? (
				  paginatedData.map((item, i) => (
					<TableRow key={item._id}>
					  <TableCell className={classes.cell}>{i + 1}</TableCell>
					  <TableCell className={classes.cell}>{gridImage(item)}</TableCell>
					  <TableCell className={classes.cell}>{item?.merchantType}</TableCell>
					  <TableCell className={classes.cell}>{item?.domainType}</TableCell>
					  <TableCell className={classes.cell} style={{textAlign: "center"}}>{gridAction(item)}</TableCell>
					</TableRow>
				  ))
				) : (
				  <TableRow>
					<TableCell colSpan={4} align="center">
					  No Data Available
					</TableCell>
				  </TableRow>
				)}
			  </TableBody>
			</Table>
			{paginatedData?.length > 0 ? (
			  <TablePagination
				count={tDatas.length}
				component="div"
				page={currentPage}
				rowsPerPage={pageSize}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[2]}
				showFirstButton={true}
				showLastButton={true}
				ActionsComponent={CustomPaginationActions}
			  />
			) : null}
		  </TableContainer>
		</div>
	  );


	// return (
	// 	<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
	// 		{/* <Header category="Page" title="Merchant Type" /> */}
	// 		<Header title="Merchant Type" />
	// 		<div class="flex space-x-2 m-4 justify-end">
	// 			<button type="button" onClick={() => addPage()} class="addButton">
	// 				New Record
	// 			</button>
	// 		</div>
	// 		<GridComponent
	// 			id="gridcomp"
	// 			dataSource={tDatas.data}
	// 			width="auto"
	// 			allowPaging
	// 			allowSorting
	// 			allowExcelExport
	// 			allowPdfExport
	// 			pageSettings={{ pageCount: 5 }}
	// 			contextMenuItems={contextMenuItems}
	// 			selectionSettings={selectionsettings}
	// 			editSettings={editing}
	// 			toolbar={toolbarOptions}
	// 			allowFiltering={true}
	// 			// filterSettings={{ignoreAccent:true, type:'Menu'}}
	// 			// filterSettings={{ ignoreAccent: true, type: "CheckBox" }}
	// 			filterSettings={{ ignoreAccent: true, type: 'Excel' }}>
	// 			<ColumnsDirective>
	// 				<ColumnDirective headerText="Sr No" template={gridSrno} textAlign="center" />
	// 				<ColumnDirective headerText="Merchant Type Image" textAlign="center" template={gridImage} />
	// 				{merchantTypeGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
	// 				<ColumnDirective headerText="Actions" template={gridActions} textAlign="Center" />
	// 			</ColumnsDirective>
	// 			<Inject
	// 				services={[
	// 					Resize,
	// 					Search,
	// 					ContextMenu,
	// 					Page,
	// 					Selection,
	// 					Toolbar,
	// 					Edit,
	// 					Sort,
	// 					Filter,
	// 					ExcelExport,
	// 					PdfExport
	// 				]}
	// 			/>
	// 		</GridComponent>
	// 	</div>
	// );
};
export default MerchantType;
