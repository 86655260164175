import React, { createContext,useEffect,useRef, useContext, useState, useReducer } from "react";
import { userGrid } from "../pages/users/config/UserConfig";
import authInitialState from "./initialStates/authInitialState";
import auth from "./reducers/auth";

const StateContext = createContext({});

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
  //LoginContext
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
};

export const ContextProvider = ({ children }) => {
  const containerRef = useRef(null);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [profileData, setProfileData] = useState([]);
  const [auth, setAuth] = useState(false);
  const [orders,setOrders]=useState([]);
  const [data, setData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [value, setValue] = useState([""]);
  const [loginData, setLoginData] = useState([]);
  const [pushNotification,setPushNotification]=useState([])
  const [token, setToken] = useState([]);

  const [statusData, setStatusData] = useState("");
  const [myOrder, setMyOrder] = useState([])
  const [alertData, setAlertData] = useState(false);
  const [orderData,setOrderData]=useState([])
  const [users,setUsers]=useState([])
  const [distributor,setDistributor]=useState([])
  const [userId,setUserId]=useState("")
  const [orderId, setOrderId] = useState([""])
  const  [userInfo,setUserInfo]=useState([])
 const [banner,setBanner]=useState([])
  const [employees,setEmployees]=useState([])
  const [employeeId,setEmployeeId]=useState("")
  const  [employeeInfo,setEmployeeInfo]=useState([])
  const [open, setOpen] = useState(false)
  const [orderInfo, setOrderInfo] = useState([])
  //LoginContext
  const setLoginPending = (isLoginPending) => useState({ isLoginPending });
  const setLoginSuccess = (isLoggedIn) => useState({ isLoggedIn });
  const setLoginError = (loginError) => useState({ loginError });

  const [authState, authDispatch] = useReducer(auth, authInitialState);

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>{
    console.log("handleClick", clicked);
    setIsClicked({ ...initialState, [clicked]: true })}

  //LoginContext
  const login = (email, password) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    fetchLogin(email, password, (error) => {
      setLoginPending(false);

      if (!error) {
        setLoginSuccess(true);
      } else {
        setLoginError(error);
      }
    });
  };

  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
  };

  const handleOutsideClick = (event) => {
    console.log(event.target, !containerRef.current.contains(event.target),"click")
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsClicked({  ...initialState }); // Reset all clicks to false
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        authState,
        authDispatch,
        login,
        logout,

        auth,
        setAuth,
        orders,setOrders,
        data,
        setData,

        viewData,
        setViewData,

        deleteData,
        setDeleteData,

        value,
        setValue,
        open, setOpen,
        loginData,
        setLoginData,
        token,
        setToken,
        myOrder,
        setMyOrder,
        orderInfo,
        setOrderInfo,
        statusData,
        setStatusData,
        alertData,
        setAlertData,
        orderData,
        setOrderData,
        users,setUsers,
        orderId, setOrderId,
        distributor,setDistributor,
        userId,setUserId,
        userInfo,setUserInfo,
        employees,setEmployees,setEmployeeId,employeeId,employeeInfo,setEmployeeInfo,banner,setBanner,profileData,setProfileData,pushNotification,setPushNotification
      }}
    >
      {/* {children} */}
      <div ref={containerRef}>
        {children}
      </div>
    </StateContext.Provider>
  );
};

export default StateContext;

export const useStateContext = () => useContext(StateContext);
