import * as React from "react";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../view.css";
import axios from "../api/axiosapi";
import StateContext from "../../contexts/ContextProvider";
import { Alert, Stack } from "@mui/material";
import Compressor from "compressorjs";
import blank from "../../assets/blank.jpg";
import { BsFillPencilFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { ToastContainer, toast } from "react-toast";
import { data } from "autoprefixer";
import Pincode from "react-pincode";
import { useEffect } from "react";
import { WithContext as ReactTags } from 'react-tag-input';

const AddDistributors = () => {
  const navigate = useNavigate();

  const { setValue } = useContext(StateContext);

  const { setStatusData } = useContext(StateContext);
  const { setAlertData } = useContext(StateContext);

  const [password, setPassword] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [pincode, setPincode] = useState(0);
  const [aadharCard, setAadharCard] = useState(0);
  const [panCard, setPanCard] = useState(0);
  const [confirmPass, setConfirmPass] = useState(0);
  const [pincodeData, setPincodeData] = useState([]);
  const [country, setCountry] = useState("India");

  const [tags, setTags] = useState([]);

  console.log(pincodeData);

  const [compressedFile, setCompressedFile] = useState("");

  const mainPage = () => {
    navigate("/distributors");
  };

  // const [newData, setData] = useState({
  //   firstName: document.getElementById("firstname").value,
  //   lastName: document.getElementById("lastname").value,
  // });

  // console.log(newData);

  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {}, [pincodeData]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  //ImagePreview
  const [image, setImage] = React.useState("");
  // const [imgUpload, setImgUpload] = useState(false);
  const [serverImage, setServerImage] = useState([""]);

  // function uploadSingleImage(e) {
  //   setImage([...image, URL.createObjectURL(e.target.files[0])]);
  //   setImage(e.target.files[0]);
  // }

  console.log("image = ", image);

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
    console.log(s);
  }

  console.log(image);

  const [accountType, setAccountType] = useState("");
  const [gender, setGender] = useState("");

  const initialState = {
    ActiveInactive: false,
    firstName: "",
    lastName: "",
    firmName: "",
    country: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    addharCard: "",
    panCard: "",
    mobile: "",
    email: "",
    password: "",
    role: "DISTRIBUTOR",
    bankName: "",
    bankAccountType: "",
    accountNo: "",
    branchName: "",
    ifscCode: "",
  };

  const [addData, setAddData] = useState(initialState);

  const [alert, setAlert] = useState(false);

  // async function handleSubmit(e) {
  //   debugger;
  //   var fname = document.getElementById("firstname").value;
  //   var lname = document.getElementById("lastname").value;
  //   var gender;
  //   if (document.getElementById("inlineRadio1").checked === true) {
  //     gender = "Male";
  //   } else {
  //     gender = "Female";
  //   }
  //   var company = document.getElementById("companyname").value;
  //   var country = document.getElementById("countryname").value;
  //   var adress = document.getElementById("address").value;
  //   var state = document.getElementById("statename").value;
  //   var city = document.getElementById("cityname").value;
  //   var pincd = document.getElementById("pincodeno").value;
  //   var adhar = document.getElementById("adharno").value;
  //   var pancard = document.getElementById("pancardno").value;
  //   var email = document.getElementById("emailid").value;
  //   var mobileno = document.getElementById("mobileno").value;
  //   var emailpass = document.getElementById("emailpassword").value;
  //   var bank = document.getElementById("bankdetails").value;
  //   var acctype;
  //   if (document.getElementById("saving").checked === true) {
  //     acctype = "Saving";
  //   } else {
  //     acctype = "Current";
  //   }
  //   var accNo = document.getElementById("accountno").value;
  //   var branchname = document.getElementById("branchname").value;
  //   var ifsccode = document.getElementById("ifscode").value;
  //   // var flexcheck;
  //   // if (document.getElementById("flexCheckDefault").checked) {
  //   //   flexcheck = true;
  //   // } else {
  //   //   flexcheck = false;
  //   // }

  //   axios
  //     .post("https://api.orbitmart.co.in/api/v1/auth/register", {
  //       firstName: fname,
  //       lastName: lname,
  //       gender: gender,
  //       shopName: company,
  //       country: country,
  //       address: adress,
  //       state: state,
  //       city: city,
  //       adharCard: adhar,
  //       panCard: pancard,
  //       email: email,
  //       mobile: mobileno,
  //       pincode: pincd,
  //       password: emailpass,
  //       role: "DISTRIBUTOR",
  //       bankName: bank,
  //       bankAccountType: acctype,
  //       accountNo: accNo,
  //       branchName: branchname,
  //       ifscCode: ifsccode,
  //       // ActiveInactive: flexcheck,
  //     })
  //     .then(function (response) {
  //       debugger;
  //       if (response.data.message === "DISTRIBUTOR Added Successfully") {
  //         alert("Distributor added successfully");
  //       } else if (
  //         response.data.message === "Password incorrect" ||
  //         response.data.message === "UserID is incorrect"
  //       ) {
  //         console.log("wrong password");
  //         //setNomatch(true);
  //       }
  //     })
  //     .catch(function (error) {
  //       debugger;
  //       console.log(error);
  //     });
  // }

  // function upload(e) {
  //   e.preventDefault();
  //   console.log(image);
  // }

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
    console.log(s);
  }

  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      debugger;
      let length = document.getElementById("password").value.length;
      setPassword(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "mobile") {
      debugger;
      let length = document.getElementById("mobile").value.length;
      setMobile(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "pincode") {
      let length = document.getElementById("pincode").value.length;
      setPincode(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "addharCard") {
      let length = document.getElementById("addharCard").value.length;
      setAadharCard(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "panCard") {
      let length = document.getElementById("panCard").value.length;
      setPanCard(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "confirmpassword") {
      let length = document.getElementById("confirmpassword").value.length;
      setConfirmPass(length);
      setAddData({ ...addData, [name]: value });
    } else {
      setAddData({ ...addData, [name]: value });
    }
  };

  const ImageThumb = ({ image }) => {
    return (
      <div>
        <img src={URL.createObjectURL(image)} alt={image.name} />
      </div>
    );
  };

  function timeOut() {
    setTimeout(() => {
      mainPage();
    }, 1000);
  }

  console.log(accountType);

  const MULTI_PIN_CODES = tags.map(tags => tags.text)

  async function postData() {
    // debugger;

    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = "https://api.orbitmart.co.in/api/v1/auth/register";
    const formData = new FormData();
    const imageFinal = image;
    // new Compressor(imageFinal, {
    //   quality: 0.6,
    //   success: (compressedResult) => {
    //     setCompressedFile(compressedResult);
    //   },
    // });
    formData.append("Image", selectedImages[0]);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        // debugger;
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          ActiveInactive: false,
          firstName: addData.firstName,
          lastName: addData.lastName,
          firmName: addData.firmName,
          country: country,
          address: addData.address,
          state: pincodeData.stateName,
          city: pincodeData.city,
          district: pincodeData.district,
          pincode: pincodeData.pincode,
		      subPincode: MULTI_PIN_CODES, 
          gender: gender,
          addharCard: addData.addharCard,
          panCard: addData.panCard,
          mobile: addData.mobile,
          email: addData.email,
          password: addData.password,
          role: "DISTRIBUTOR",
          bankName: addData.bankName,
          bankAccountType: accountType,
          accountNo: addData.accountNo,
          branchName: addData.branchName,
          ifscCode: addData.ifscCode,
          avatar: coverPhoto,
        };
        console.log(finalData);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions).then((res) => {
          // debugger;
          console.log(res.status);
          if (res.status === 201 || res.status === 200) {
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          } else {
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 1000);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const NUMBER = 6;
  const MAXTAGS = 3;

  return (
    <div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
      {console.log(alert, "alert", tags)}
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      {/* {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "top",
            align: "center",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null} */}
      <h1 class="font-medium  flex space-x-2  leading-tight text-4xl  mt-0 mb-2 text-blue-700">
        Add Distributor
      </h1>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
      </div>

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstName"
              name="firstName"
              value={addData.firstName}
              placeholder="Enter First Name"
              onChange={handleChange}
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastName"
              name="lastName"
              value={addData.lastName}
              placeholder="Enter Last Name"
              onChange={handleChange}
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="gender"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Gender
            </label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="gender"
                value="male"
                onChange={handleChange}
              />
              <label
                class="form-check-label inline-block text-gray-800"
                for="gender"
              >
                Male
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  mr-2 cursor-pointer"
                type="radio"
                name="gender"
                value="female"
                onChange={handleChange}
              />
              <label
                class="form-check-label inline-block text-gray-800"
                for="inlineRadio20"
              >
                Female
              </label>
            </div>
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Firm Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firmName"
              name="firmName"
              value={addData.firmName}
              placeholder="Enter Firm Name"
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Email Address</label>
            <input
              type="email"
              class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              value={addData.email}
              placeholder="Enter Email Address"
              onChange={handleChange}
              required
            />
            <p class="invisible peer-invalid:visible text-red-700 font-light">
              Please enter your email address
            </p>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile No:</label>
            <input
              type="number"
              class="form-control  peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobile"
              name="mobile"
              value={addData.mobile}
              placeholder="Enter Mobile No"
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 10);
              }}
              required
            />
            {mobile == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You have 10 digit mobile number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid Mobile Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Password</label>
            <input
              type="password"
              class="form-control  peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="password"
              name="password"
              value={addData.password}
              placeholder="Enter Password"
              autocomplete="off"
              onChange={handleChange}
              required
            />
            {password >= 6 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                Now you have a Valid Password
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Atleast 6 Digit Password
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Confirm Password</label>
            <input
              type="password"
              class="form-control  peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="confirmpassword"
              name="confirmpassword"
              placeholder="Repeat Password"
              value={addData.confirmpassword}
              onChange={handleChange}
              required
            />
            {addData.password === addData.confirmpassword ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                Password do match
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Password do not match
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="accountType"
              onChange={(e) => setGender(e.target.value)}
            >
              <option>-- Select Type --</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              value={addData.address}
              placeholder="Enter Your Address"
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pin Code</label>
            <Pincode
              getData={(data) => setPincodeData(data)}
              pincodeInput={{
                width: "100%",
                height: 35,
                border: "2px solid grey",
                borderRadius: 5,
                paddingLeft: "12px",
              }}
              showArea={false}
              showCity={false}
              showState={false}
              showDistrict={false}
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pin Code</label>
            <input
              type="text"
              class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pincode"
              name="pincode"
              value={addData.pincode}
              placeholder="Enter Your Pincode"
              onChange={handleChange}
              onWheel={e => e.target.blur()}
              onInput = {(e) =>{
                e.target.value = (e.target.value).toString().slice(0,6)
            }}
            />
            {pincode == 6 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                Now you have a Valid Pincode
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Valid 6 Digit Pincode
              </p>
            )}
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              value={pincodeData.city}
              placeholder="Enter Your City"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>District</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="district"
              name="district"
              value={pincodeData.district}
              placeholder="Enter Your District"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="stateName"
              value={pincodeData.stateName}
              placeholder="Enter Your State"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"
              value={country}
              placeholder="Enter Your Country"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Aadhaar Card</label>
            <input
              type="number"
              class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="addharCard"
              name="addharCard"
              value={addData.addharCard}
              defaultValue={"India"}
              placeholder="Enter Aadhaar Card Number"
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 12);
              }}
            />
            {aadharCard == 12 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                Now you have a Valid Aadhaar Card Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Valid Aadhaar Card Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="panCard"
              name="panCard"
              value={addData.panCard}
              placeholder="Enter Pan Card Number"
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 10);
              }}
            />
            {panCard == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                Now you have a Valid Pan Card Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Valid Pan Card Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankdetails"
              name="bankName"
              value={addData.bankName}
              onChange={handleChange}
              placeholder="Enter Bank Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="accountType"
              onChange={(e) => setAccountType(e.target.value)}
            >
              <option>-- Select Type --</option>
              <option value="SAVING">Saving Account</option>
              <option value="CURRENT">Current Account</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account No</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="accountNo"
              value={addData.accountNo}
              onChange={handleChange}
              placeholder="Enter Account No"
              onWheel={(e) => e.target.blur()}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchName"
              name="branchName"
              value={addData.branchName}
              onChange={handleChange}
              placeholder="Enter Branch Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifscode"
              name="ifscCode"
              value={addData.ifscCode}
              onChange={handleChange}
              placeholder="Enter IFSC Code"
            />
          </div>

		  <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Additional Pin Code</label>
            <ReactTags
			classNames={{
				tags: 'tagsClass',
				tagInput: 'tagInputClass',
				tagInputField: 'tagInputFieldClass',
				selected: 'selectedClass',
				tag: 'tagClass',
				remove: 'removeClass',
				suggestions: 'suggestionsClass',
				activeSuggestion: 'activeSuggestionClass',
				editTagInput: 'editTagInputClass',
				editTagInputField: 'editTagInputField',
				clearAll: 'clearAllClass',
			  }}
              tags={tags}
			//   maxTags= {MAXTAGS}
            //   suggestions={suggestions}
            //   delimiters={delimiters}
			placeholder= "Add Pin"
			inline
			// inputFieldPosition="inline"
			maxLength = {NUMBER}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
            //   handleDrag={handleDrag}
              handleTagClick={handleTagClick}
            //   autocomplete
            />
          </div>

          <div>
            <label>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="Status"
              class="form-label inline-block mb-2 text-gray-700"
            ></label>
            <input
              class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label
              class="form-check-label inline-block text-gray-800"
              for="flexCheckDefault"
            >
              Active / Inactive
            </label>
          </div> */}
        </div>
      </form>
      <div class="flex space-x-2 justify-end mr-2">
        <button
          type="submit"
          style={{ width: "8%" }}
          class="addButton"
          onClick={postData}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddDistributors;
