import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import {
  contextMenuItems,
  userGrid,
  userData,
} from "./config/DomainTypeConfig";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import axios from "axios";

const DomainType = () => {
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Search"];

  const editing = { allowDeleting: true };
  const [category, setCategory] = useState([""]);

  const loadcategory = async () => {
    const result = await axios.get(
      `https://api.orbitmart.co.in/api/v1/categoryadd`
    );
    const { data } = result.data;
    setCategory(data.reverse());
  };

  console.log(category);

  useEffect(() => {
    loadcategory();
  }, []);

  const navigate = useNavigate();

  const addItem = () => {
    navigate("/domainType/add");
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Domain Type" />
      <div className="flex space-x-2 m-4 justify-end">
        <button type="button" className="addButton" onClick={() => addItem()}>
          New Record
        </button>
      </div>
      <GridComponent
        id="gridcomp"
        dataSource={category}
        width="auto"
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        pageSettings={{ pageCount: 5 }}
        contextMenuItems={contextMenuItems}
        selectionSettings={selectionsettings}
        editSettings={editing}
        toolbar={toolbarOptions}
        allowFiltering={true}
        // filterSettings={{ignoreAccent:true, type:'Menu'}}
        // filterSettings={{ ignoreAccent: true, type: "CheckBox" }}
        filterSettings={{ ignoreAccent: true, type: "Excel" }}
      >
        <ColumnsDirective>
          {userGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Search,
            ContextMenu,
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default DomainType;
