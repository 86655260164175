import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StateContext from '../../contexts/ContextProvider';
import { BsFillPencilFill } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';
import { setValue } from '@syncfusion/ej2/base';
import blank from '../../assets/blank.jpg';
import { Alert, Stack } from '@mui/material';

const EditMerchantType = () => {
	const navigate = useNavigate();

	const [ alert, setAlert ] = useState(false);
	const [ error, setError ] = useState(false);

	const mainPage = () => {
		navigate('/merchantType');
	};

	const [ selectedImages, setSelectedImages ] = useState([]);

	const onSelectFile = (event) => {
		// const selectedFiles = event.target.files;
		const selectedFiles = event.target.files[0];
		// const selectedFilesArray = Array.from(selectedFiles);

		// const imagesArray = selectedFilesArray.map((file) => {
		//   return file;
		// });

		setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
		// setSelectedImages((previousImages) => previousImages.concat(imagesArray));

		// FOR BUG IN CHROME
		event.target.value = '';
	};

	function deleteHandler(image) {
		setSelectedImages(selectedImages.filter((e) => e !== image));
		URL.revokeObjectURL(image);
	}

	const { data } = useContext(StateContext);
	console.log(data);

	const [ updatedValue, setUpdatedValue ] = useState(data);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUpdatedValue({ ...updatedValue, [name]: value });
	};

	function postData(e) {
		debugger;
		const editId = data._id;
		const uploadImage = 'https://api.orbitmart.co.in/api/v1/upload';
		const uploadData = `https://api.orbitmart.co.in/api/v1/merchant/Type/${editId}`;
		const formData = new FormData();
		const imageFinal = selectedImages[0];
		formData.append('Image', imageFinal);
		axios
			.post(uploadImage, formData)
			.then((res) => {
				debugger;
				console.log(res);
				const newimg = res.data.files;
				const coverPhoto = newimg[0];
				const finalData = {
					domainType: updatedValue.domainType,
					merchantType: updatedValue.merchantType,
					image: coverPhoto
				};
				const requestOptions = {
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(finalData)
				};
				fetch(uploadData, requestOptions)
					.then((res) => {
						console.log(res);
						setAlert(true);
						setTimeout(() => {
							mainPage();
						}, 1000);
					})
					.catch((err) => {
						setError(true);
						console.log(err);
						setTimeout(() => {
							mainPage();
						}, 1000);
					});
			})
			.catch((err) => {
				setError(true);
				console.log(err);
				setTimeout(() => {
					mainPage();
				}, 1000);
			});
	}

	// async function handleSubmit(e) {
	//   debugger;
	//   const editId = data._id;
	//   e.preventDefault();
	//   debugger;
	//   try {
	//     const res = await fetch(
	//       `https://api.orbitmart.co.in/api/v1/merchant/Type/${editId}`,
	//       {
	//         method: "put",
	//         headers: {
	//           "Content-Type": "application/json",
	//           "x-access-token": "token-value",
	//         },
	//         body: JSON.stringify(updatedValue),
	//       }
	//     );
	//     if (!res.ok) {
	//       const message = `An error has occured: ${res.status} - ${res.statusText}`;
	//       throw new Error(message);
	//     }
	//     const data = await res.json();
	//     const result = {
	//       status: res.status + "-" + res.statusText,
	//       headers: { "Content-Type": res.headers.get("Content-Type") },
	//       data: data,
	//     };
	//     console.log(result);
	//   } catch (err) {
	//     console.log(err.message);
	//   }
	//   mainPage();
	// }

	return (
		<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-2xl">
			{alert ? (
				<Stack
					sx={{ width: '30%' }}
					style={{
						position: 'fixed',
						left: '22%',
						bottom: '1%',
						width: '30%'
					}}
					spacing={2}>
					<Alert variant="filled" severity="success">
						Data Updated Successfully
					</Alert>
				</Stack>
			) : null}
			{error ? (
				<Stack
					sx={{ width: '30%' }}
					style={{
						position: 'fixed',
						left: '22%',
						bottom: '1%',
						width: '30%'
					}}
					spacing={2}>
					<Alert variant="filled" severity="error">
						Sorry, Data Cannot be Updated at this moment. Please try Again!
					</Alert>
				</Stack>
			) : null}
			<h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">Edit Merchant Type</h4>
			<div class="flex space-x-2 justify-end">
				<button
					type="button"
					class="backButton"
					style={{ width: '8%', alignContent: 'center', alignItems: 'center' }}
					onClick={() => mainPage()}>
					Back
				</button>
				<button type="button" style={{ width: '8%' }} class="addButton" onClick={postData}>
					Edit
				</button>
			</div>

			<form>
				<div class="grid xl:grid-cols-3  m:gap-2">
					<div class="relative z-0 p-2 w-full mb-6 group">
						<label for="merchantType" class="form-label inline-block mb-2 text-gray-700">
							Merchant Type
						</label>

						<select
							class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							aria-label="Default select example"
							id="merchantType"
							name="merchantType"
							value={updatedValue.merchantType}
							onChange={handleChange}>
							// onChange={(e) => setFirstname(e.target.value)}
							// placeholder="Enter Type"
							{/* <option selected>Restraunt</option> */}
							<option value="RETAILER">RETAILER</option>
							<option value="WHOLESALER">WHOLESALER</option>
							<option value="MANIFACTURER">MANIFACTURER</option>
							{/* <option value="4">The Second Wife</option> */}
						</select>
					</div>
					<div class="relative z-0 p-2 w-full mb-6 group">
						<label for="domainType" class="form-label inline-block mb-2 text-gray-700">
							Domain Type
						</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="domainType"
							value={updatedValue.domainType}
							onChange={handleChange}
						/>
					</div>

					<div>
						<label>Image</label>
						<div class="relative mt-1 flex justify-center w-40">
							<div class=" absolute right-0 top-0 z-10 flex">
								<label
									for="file-upload"
									class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
									<BsFillPencilFill />
									<input
										id="file-upload"
										name="file-upload"
										disabled={selectedImages.length == 1}
										accept="image/*"
										type="file"
										onChange={onSelectFile}
										class="sr-only"
									/>
								</label>
							</div>
							<div class="relative z-0 p-2 w-full mb-6 group">
								{selectedImages.length > 0 ? (
									selectedImages.map((item, index) => {
										return (
											<div style={{ display: 'inline-block' }} key={item}>
												<p className="block-icon">
													<img src={URL.createObjectURL(item)} alt="img" />
													<ImCross
														style={{ cursor: 'pointer' }}
														onClick={() => deleteHandler(item)}
														className="icon-belowtag"
													/>
												</p>
											</div>
										);
									})
								) : (
									<div>
										<img src={data.image} alt="img" />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default EditMerchantType;
