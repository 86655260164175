import { Link } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import NewToggle from "../../newToggle/NewToggle";
import DeleteModal from "../../deleteModal/DeleteModal";

const gridEmployeeEdit = () => (
  <div className="flex items-center justify-center gap-2">
    <h1
      style={{ color: "#8be78b", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link style={{ marginRight: "auto" }} to="/employees/edit">
        <BsFillPencilFill />
      </Link>
    </h1>
    <h1
      style={{ color: "#03c9d7", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link
        style={{ marginLeft: "auto", marginRight: "auto" }}
        to="/employees/view"
      >
        <FiEye />
      </Link>
    </h1>
    <h1
      style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <DeleteModal />
    </h1>
  </div>
);

const gridEmployeeStatus = () => (
  <div style={{ paddingLeft: 12 }} class="flex items-center justify-center">
    <NewToggle />
  </div>
);

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const employeeGrid = [
  // {
  //   field: "id",
  //   headerText: "Id",
  //   width: "10",
  //   textAlign: "Center",
  //   isPrimaryKey: true,
  // },
  {
    field: "firstName",
    headerText: `FirstName`,
    width: "20",
    textAlign: "Center",
  },
  {
    field: "lastName",
    headerText: "Last Name",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "mobile",
    headerText: "Mobile No",
    width: "30",
    textAlign: "Center",
  },
  {
    field: "email",
    headerText: "Email",
    width: "30",
    textAlign: "Center",
  },
  {
    headerText: "Status",
    template: gridEmployeeStatus,
    field: "id",
    textAlign: "Center",
    width: "25",
  },
  {
    field: "action",
    headerText: "Action",
    width: "20",
    textAlign: "Center",
    template: gridEmployeeEdit,
  },
];

export const employeeData = [
  {
    id: 1,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 2,
    firstname: "Nisha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 3,
    firstname: "Jack",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 4,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 5,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 6,
    firstname: "Priya",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "inactive",
    StatusBg: "#FB9678",
  },
  {
    id: 7,
    firstname: "Suraj",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 8,
    firstname: "Rahul",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "inactive",
    StatusBg: "#FB9678",
  },
  {
    id: 9,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 10,
    firstname: "Anubhav",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 11,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 12,
    firstname: "Nisha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 13,
    firstname: "Jack",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 14,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 15,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 16,
    firstname: "Priya",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "inactive",
    StatusBg: "#FB9678",
  },
  {
    id: 17,
    firstname: "Suraj",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 18,
    firstname: "Rahul",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "inactive",
    StatusBg: "#FB9678",
  },
  {
    id: 19,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 20,
    firstname: "Anubhav",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 21,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 22,
    firstname: "Nisha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 23,
    firstname: "Jack",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 24,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 25,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 26,
    firstname: "Priya",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "inactive",
    StatusBg: "#FB9678",
  },
  {
    id: 27,
    firstname: "Suraj",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 28,
    firstname: "Rahul",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "inactive",
    StatusBg: "#FB9678",
  },
  {
    id: 29,
    firstname: "Asha",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
  {
    id: 30,
    firstname: "Anubhav",
    lastname: "Singa",
    mobileno: 9898989898,
    email: "a@gmail.com",
    Status: "active",
    StatusBg: "#03C9D7",
  },
];
