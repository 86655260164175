import React from 'react';
import {
	GridComponent,
	Inject,
	ColumnsDirective,
	ColumnDirective,
	Search,
	Page,
	Selection,
	Edit,
	Toolbar,
	Sort,
	Filter,
	Resize,
	ContextMenu,
	ExcelExport,
	PdfExport
} from '@syncfusion/ej2-react-grids';
// import {
//   contextMenuItems,
//   merchantsGrid,
//   merchantsData,
// } from "./config/MerchantConfig";
import '../css/view.css';
import { Header } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsFillPencilFill } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import DeleteModal from './DeleteModal';
import { useContext } from 'react';
import StateContext from '../../contexts/ContextProvider';
import axios from 'axios';
import "./SendNotificationTo.css";
import { MultiSelect } from "react-multi-select-component";


const popupStyles = {
	border: 'none',
	padding: '3px 10px',
	borderRadius: '5px',
	width: '500px'
  };
  
  const chipStyles = {
	border: 'none',
	padding: '3px 10px',
	borderRadius: '5px',
	backgroundColor: '#f2f2f2'
  };

const Notification = () => {
	const { pushNotification, setPushNotification } = useContext(StateContext);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selected, setSelected] = useState([]);
	const [selectedPincode, setSelectedPincode] = useState([])
	const [selectContent, setSelectContent] = useState({
		title: '',
		body: ''
	  });

	const updateTitle = (newTitle) => {
		setSelectContent((prevState) => ({
		  ...prevState,
		  title: newTitle
		}));
	  };
	  
	  // Function to update the body
	  const updateBody = (newBody) => {
		setSelectContent((prevState) => ({
		  ...prevState,
		  body: newBody
		}));
	  };
 
	const handleOptionSelect = (option) => {
	  setSelectedOption(option);
	};

	const token = sessionStorage.getItem('token');
		const config = {
			headers: {
				"x-token": token,
			}
		}

		// function transformData(data) {
		// 	return data.map((pincode, index) => ({
		// 	  id: index,
		// 	  pincode: pincode
		// 	}));
		//   }

	const handlePincodesSelect = async() => {
		// try {
		// 	const {data} = await axios.get('https://api.orbitmart.co.in/api/v1/auth/admin/pincode', config)
		// 	const transformedData = transformData(data?.data);
		// 	setSelectedPincode(transformedData)
		// 	console.log(transformedData)
		// }catch (e) {
		// 	console.error(e);
		// }

		try {
			const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/admin/pincode', config);
			const data = response.data;
			console.log('Response data:', data); // Log the entire response data
		  
			// Assuming the actual data you need is in data.data and it should be an array
			if (Array.isArray(data.data.pincode)) {
			  const transformedData = transformData(data.data.pincode);
			  setSelectedPincode(transformedData);
			  console.log(transformedData);
			} else {
			  console.error('Expected an array but received:', data.data);
			}
		  } catch (error) {
			console.error('Error fetching data', error);
		  }
		  
		  function transformData(data) {
			return data.map((pincode) => ({
				label: pincode,
				value: pincode
			}));
		  }
		  
	}

	const loadData = async () => {
		const body = {
			"title": selectContent.title,
			"body": selectContent.body,
			"role": selectedOption.toUpperCase(),
			"pincode": selected.map(pincode =>pincode.value)
		}
		await axios
			.post(`https://api.orbitmart.co.in/api/v1/auth/admin/push-notification`,body ,config)
			.then((res) => {setPushNotification(res.data.data)
				
			});
	};

	useEffect(() => handlePincodesSelect(),[])

	console.log(pushNotification, selectedPincode)

	const { setViewData } = useContext(StateContext);
	const { setValue } = useContext(StateContext);
	const { value } = useContext(StateContext);
	// const itemId = tDatas.data;
	// push_notification
	// function gridActions(itemId) {
	// 	return (
	// 		<div className="flex items-center justify-center gap-2">
	// 			<h1
	// 				style={{ color: '#8be78b', display: 'flex' }}
	// 				className="font-small leading-tight text-xl mt-0 mb-2 ">
	// 				<Link style={{ marginRight: 'auto' }} to={`/notification/edit`}>
	// 					<BsFillPencilFill onClick={() => itemEdit(itemId)} />
	// 				</Link>
	// 			</h1>
	// 			<h1
	// 				style={{ color: '#03c9d7', display: 'flex' }}
	// 				className="font-small leading-tight text-xl mt-0 mb-2 ">
	// 				<Link style={{ marginLeft: 'auto', marginRight: 'auto' }} to={`/notification/view`}>
	// 					<FiEye onClick={() => itemView(itemId)} />
	// 				</Link>
	// 			</h1>
	// 			<h1
	// 				style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
	// 				className="font-small leading-tight text-xl mt-0 mb-2 ">
	// 				<DeleteModal itemId={itemId._id} />
	// 			</h1>
	// 		</div>
	// 	);
	// }

	const itemView = (itemId) => {
		setViewData(itemId);
	};

	const itemEdit = (itemId) => {
		setViewData(itemId);
	};

	const navigate = useNavigate();


	return (
		<>
		<style>
		{`
		.custom-multi-select .options {
          display: block;
		}
		`}	
		</style>
		  <div className="tableflex">
			<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
			  <h1 className="page-heading">Send Notification To</h1>
			  <div className='main-Notifi-container'>
			  <div className="container">
				<div className="options">
				<div className="multi-select-container" style={{ zIndex: "1002" }}>
              <MultiSelect
                id="chip-customization"
                options={selectedPincode}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                className="custom-multi-select"
                popupSettings={{
                  cssClass: "custom-popup",
                  style: popupStyles,
                }}
                chipCustomization={{
                  avatarText: "name",
                  text: "name",
                  cssClass: "custom-chip",
                  style: chipStyles,
                }}
				overrideStrings={{
					selectSomeItems: "Pincode",
				  }}
              />
            </div>
				<label
					className={`option-label ${
					  selectedOption === "Distributor" ? "selected" : ""
					}`}
					onClick={() => handleOptionSelect("Distributor")}
				  >
					<input
					  type="radio"
					  name="option"
					  value="Merchant"
					  checked={selectedOption === "Distributor"}
					  style={{ margin: "5px" }}
					  readOnly
					/>
					Distributor
				  </label>
				  <label
					className={`option-label ${
					  selectedOption === "Merchant" ? "selected" : ""
					}`}
					onClick={() => handleOptionSelect("Merchant")}
				  >
					<input
					  type="radio"
					  name="option"
					  value="Merchant"
					  checked={selectedOption === "Merchant"}
					  style={{ margin: "5px" }}
					  readOnly
					/>
					Merchant
				  </label>
				  <label
					className={`option-label ${
					  selectedOption === "Employee" ? "selected" : ""
					}`}
					onClick={() => handleOptionSelect("Employee")}
				  >
					<input
					  type="radio"
					  name="option"
					  value="Employee"
					  checked={selectedOption === "Employee"}
					  style={{ margin: "5px" }}
					  readOnly
					/>
					Employee
				  </label>
				  <label
					className={`option-label ${
					  selectedOption === "User" ? "selected" : ""
					}`}
					onClick={() => handleOptionSelect("User")}
				  >
					<input
					  type="radio"
					  name="option"
					  value="User"
					  checked={selectedOption === "User"}
					  style={{ margin: "5px" }}
					  readOnly
					/>
					User
				  </label>
				</div>
				<input
				  type="text"
				  className="textbox"
				  placeholder="Enter Title..."
				  value={selectContent.title}
				  onChange={(e)=> updateTitle(e.target.value)}
				/>
				<textarea className="textarea" placeholder="Enter Body..."  value={selectContent.body}
				  onChange={(e)=> updateBody(e.target.value)} />
				<button className="button" onClick={loadData}>Send</button>
			  </div>
			  </div>
			</div>
		  </div>
		</>
	  );



	// return (
	// 	<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
	// 		<Header title="Notification" />
	// 		{/* <Header category="Page" title="Notification" /> */}
	// 		<div class="flex space-x-2 m-4 justify-end">
	// 			<button type="button" onClick={() => addPage()} class="addButton">
	// 				New Record
	// 			</button>
	// 		</div>
	// 		<GridComponent
	// 			id="gridcomp"
	// 			dataSource={pushNotification}
	// 			width="auto"
	// 			allowPaging
	// 			allowSorting
	// 			allowExcelExport
	// 			allowPdfExport
	// 			pageSettings={{ pageCount: 5 }}
	// 			contextMenuItems={contextMenuItems}
	// 			selectionSettings={selectionsettings}
	// 			editSettings={editing}
	// 			toolbar={toolbarOptions}
	// 			allowFiltering={true}
	// 			// filterSettings={{ignoreAccent:true, type:'Menu'}}
	// 			// filterSettings={{ ignoreAccent: true, type: "CheckBox" }}
	// 			filterSettings={{ ignoreAccent: true, type: 'Excel' }}>
	// 			<ColumnsDirective>
	// 				<ColumnDirective
	// 					field="_id"
	// 					headerText="ID"
	// 					textAlign="center"
	// 					isPrimaryKey={true}
	// 					visible={false}
	// 				/>
	// 				<ColumnDirective field="title" headerText="Title" textAlign="center" width="100" />
	// 				<ColumnDirective field="body" headerText="Body" textAlign="center" width="100" />

	// 				<ColumnDirective field="topic" headerText="Topic" textAlign="Center" width="100" />

	// 				<ColumnDirective
	// 					field="_id"
	// 					headerText="Action"
	// 					textAlign="Center"
	// 					width="60"
	// 					template={gridActions}
	// 					allowFiltering={false}
	// 				/>
	// 			</ColumnsDirective>
	// 			<Inject
	// 				services={[
	// 					Resize,
	// 					Search,
	// 					ContextMenu,
	// 					Page,
	// 					Selection,
	// 					Toolbar,
	// 					Edit,
	// 					Sort,
	// 					Filter,
	// 					ExcelExport,
	// 					PdfExport
	// 				]}
	// 			/>
	// 		</GridComponent>
	// 	</div>
	// );
};

export default Notification;
