import * as React from "react";
import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import "../../pages/view.css";
import { Link } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { render } from "react-dom";
import { useNavigate } from "react-router-dom";
import { API } from '../../config/api';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const DeleteModal = (data) => {
  const [open, setOpen] = React.useState(false);

  const { setValue } = useContext(StateContext);

  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function mainPage() {
    navigate("/unitQuantity");
  }

  const itemDelete = (data) => {
    debugger;
    const id = Object.values(data);
    fetch(`${API}/unitQuantity/${id[0]}`, {
      method: "DELETE",
    })
      .then(async (response) => {
        debugger;
        const data = await response.json();
        console.log(data);
        setValue(data.code);
        handleClose();
        mainPage();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        console.log("Delete successful");
      })
      .catch((error) => {
        console.error("There was an error!", error);
        handleClose();
        mainPage();
      });
  };

  return (
    <div>
      <button
        type="button"
        style={{ marginTop: 1, alignItems: "center" }}
        onClick={handleOpen}
      >
        <AiFillDelete />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{ color: "#ff5c8e", display: "flex" }}
            className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
          >
            <RiErrorWarningLine />
          </div>

          <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            Are you sure?
          </h1>

          <h4 class="flex space-x-2 justify-center aline-center">
            you wan't be able to revert this!
          </h4>

          <br />

          <div class="flex space-x-2 justify-center aline-center">
            <button type="button" class="backButton"  style={{marginLeft: '0'}} onClick={handleClose}>
              Cancel
            </button>
            <button
              type="button"
              class="addButton"
              onClick={() => itemDelete(data)}
            >
              Yes, Delete!
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
