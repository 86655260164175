import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImCross } from 'react-icons/im';
import blank from '../../assets/blank.jpg';
import { BsFillPencilFill } from 'react-icons/bs';
import '../view.css';
import Compressor from 'compressorjs';
import axios from 'axios';
import StateContext from '../../contexts/ContextProvider';
import Pincode from 'react-pincode';
import { Alert, Stack } from '@mui/material';

const AddMerchant = () => {
	const navigate = useNavigate();

	const mainPage = () => {
		navigate('/merchants');
	};

	const { setValue } = useContext(StateContext);
	const [ errMsg, setErrMsg ] = useState('');
	const [ tDatas, setTDatas ] = useState([ '' ]);
	const [ types, setTypes ] = useState([ '' ]);

	const [ distributor, setDistributor ] = useState('');
	const [ domainType, setDomainType ] = useState('');
	const [ classification, setClassification ] = useState('');
	const [ gender, setGender ] = useState('');
	const [ accountType, setAccountType ] = useState('');
	const [ pincodeData, setPincodeData ] = useState([]);
	const [ password, setPassword ] = useState(0);
	const [ mobile, setMobile ] = useState(0);
	const [ pincode, setPincode ] = useState(0);
	const [ aadharCard, setAadharCard ] = useState(0);
	const [ panCard, setPanCard ] = useState(0);
	const [ country, setCountry ] = useState('India');
	console.log(domainType);

	const [ data, setData ] = useState([ '' ]);

	const [ selectedImages, setSelectedImages ] = useState([]);

	const [ alert, setAlert ] = useState(false);
	const [ error, setError ] = useState(false);

	const onSelectFile = (event) => {
		// const selectedFiles = event.target.files;
		const selectedFiles = event.target.files[0];
		// const selectedFilesArray = Array.from(selectedFiles);

		// const imagesArray = selectedFilesArray.map((file) => {
		//   return file;
		// });

		setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
		// setSelectedImages((previousImages) => previousImages.concat(imagesArray));

		// FOR BUG IN CHROME
		event.target.value = '';
	};

	function deleteHandler(image) {
		setSelectedImages(selectedImages.filter((e) => e !== image));
		URL.revokeObjectURL(image);
	}

	const fetchType = () => {
		fetch('https://api.orbitmart.co.in/api/v1/merchant/Type')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setTypes(data.data);
			});
	};

	const fetchData = () => {
		fetch('https://api.orbitmart.co.in/api/v1/auth/allDistributor')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setTDatas(data.data);
			});
	};

	useEffect(
		() => {
			fetchData();
			fetchType();
		},
		[ pincodeData ]
	);

	//ImagePart

	const [ file, setFile ] = React.useState('');
	const [ compressedFile, setCompressedFile ] = useState('');
	const [ serverImage, setServerImage ] = useState();

	function uploadSingleImage(e) {
		setFile(e.target.files[0]);
	}

	const ImageThumb = ({ image, icon }) => {
		return (
			<div>
				<img src={URL.createObjectURL(image)} alt={image.name} />
			</div>
		);
	};

	function deleteImage(e) {
		const s = file.filter((item, index) => index != e);
		setFile(s);
		console.log(s);
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'password') {
			debugger;
			let length = document.getElementById('password').value.length;
			setPassword(length);
			setData({ ...data, [name]: value });
		} else if (name === 'mobile') {
			debugger;
			let length = document.getElementById('mobile').value.length;
			setMobile(length);
			setData({ ...data, [name]: value });
		} else if (name === 'pincode') {
			let length = document.getElementById('pincode').value.length;
			setPincode(length);
			setData({ ...data, [name]: value });
		} else if (name === 'addharCard') {
			let length = document.getElementById('addharCard').value.length;
			setAadharCard(length);
			setData({ ...data, [name]: value });
		} else if (name === 'panCard') {
			let length = document.getElementById('panCard').value.length;
			setPanCard(length);
			setData({ ...data, [name]: value });
		} else {
			setData({ ...data, [name]: value });
		}
		// setData({ ...data, [name]: value });
	};

	console.log(data);

	function timeOut() {
		setTimeout(() => {
			mainPage();
		}, 1000);
	}

	// async function postData() {
	// 	const uploadImage = 'https://api.orbitmart.co.in/api/v1/upload';
	// 	const uploadData = 'https://api.orbitmart.co.in/api/v1/auth/register';
	// 	const formData = new FormData();
	// 	const imageFinal = file;
	// 	formData.append('Image', selectedImages[0]);
	// 	await axios
	// 		.post(uploadImage, formData)
	// 		.then((res) => {
	// 			// console.log(res);
	// 			const newimg = res.data.files;
	// 			const coverPhoto = newimg[0];
	// 			const finalData = {
	// 				role: 'MERCHANT',
	// 				classification: classification,
	// 				merchantTypeId: domainType,
	// 				distributorId: distributor,
	// 				firstName: data.firstName,
	// 				lastName: data.lastName,
	// 				// gender: gender,
	// 				firmName: data.firmName,
	// 				address: data.address,
	// 				state: pincodeData.stateName,
	// 				city: pincodeData.city,
	// 				district: pincodeData.district,
	// 				pincode: pincodeData.pincode,
	// 				country: country,
	// 				addharCard: data.addharCard,
	// 				panCard: data.panCard,
	// 				mobile: data.mobile,
	// 				email: data.email,
	// 				password: data.password,
	// 				bankName: data.bankName,
	// 				branchName: data.branchName,
	// 				bankAccountType: accountType,
	// 				accountNo: data.accountNo,
	// 				ifscCode: data.ifscCode,
	// 				avatar: coverPhoto
	// 			};
	// 			console.log(finalData);
	// 			const requestOptions = {
	// 				method: 'POST',
	// 				headers: { 'Content-Type': 'application/json' },
	// 				body: JSON.stringify(finalData)
	// 			};
	// 			fetch(uploadData, requestOptions).then((res) => {
	// 				console.log(res);
	// 				if (res.status === 201) {
	// 					setAlert(true);
	// 					setTimeout(() => {
	// 						mainPage();
	// 					}, 1000);
	// 				} else {
	// 					setError(true);

	// 					setTimeout(() => {
	// 						setError(false);
	// 					}, 1000);
	// 				}
	// 			});
	// 		})
	// 		.catch((err) => {
	// 			setError(true);
	// 			setTimeout(() => {
	// 				mainPage();
	// 			}, 1000);
	// 			console.log(err);
	// 		});
	// }

	async function postData() {
		// Validate pincode
		if (!/^\d{6}$/.test(pincodeData.pincode)) {
			setError(true); // Display error message
			console.error('Pincode must be exactly 6 digits.');
			setTimeout(() => {
				setError(false); // Hide error after a short delay
			}, 2000);
			return; // Stop function execution if pincode is invalid
		}
	
		const uploadImage = 'https://api.orbitmart.co.in/api/v1/upload';
		const uploadData = 'https://api.orbitmart.co.in/api/v1/auth/register';
		const formData = new FormData();
		const imageFinal = file;
		formData.append('Image', selectedImages[0]);
	
		await axios
			.post(uploadImage, formData)
			.then((res) => {
				const newimg = res.data.files;
				const coverPhoto = newimg[0];
				const finalData = {
					role: 'MERCHANT',
					classification: classification,
					merchantTypeId: domainType,
					distributorId: distributor,
					firstName: data.firstName,
					lastName: data.lastName,
					firmName: data.firmName,
					address: data.address,
					state: pincodeData.stateName,
					city: pincodeData.city,
					district: pincodeData.district,
					pincode: pincodeData.pincode,
					country: country,
					addharCard: data.addharCard,
					panCard: data.panCard,
					mobile: data.mobile,
					email: data.email,
					password: data.password,
					bankName: data.bankName,
					branchName: data.branchName,
					bankAccountType: accountType,
					accountNo: data.accountNo,
					ifscCode: data.ifscCode,
					avatar: coverPhoto,
				};
				console.log(finalData);
	
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(finalData),
				};
	
				fetch(uploadData, requestOptions).then((res) => {
					console.log(res);
					if (res.status === 201) {
						setAlert(true);
						setTimeout(() => {
							mainPage();
						}, 1000);
					} else {
						setError(true);
						setTimeout(() => {
							setError(false);
						}, 1000);
					}
				});
			})
			.catch((err) => {
				setError(true);
				setTimeout(() => {
					mainPage();
				}, 1000);
				console.error(err);
			});
	}
	

	return (
		<div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
			{alert ? (
				<Stack
					sx={{ width: '30%' }}
					style={{ position: 'fixed', left: '22%', bottom: '1%', width: '30%' }}
					spacing={2}>
					<Alert variant="filled" severity="success">
						Data Added Successfully
					</Alert>
				</Stack>
			) : null}
			{error ? (
				<Stack
					sx={{ width: '30%' }}
					style={{ position: 'fixed', left: '22%', bottom: '1%', width: '30%' }}
					spacing={2}>
					<Alert variant="filled" severity="error">
						Sorry, Data Cannot be Added at this moment. Please try Again!
					</Alert>
				</Stack>
			) : null}
			<h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">Add Merchant</h4>
			<div class="flex space-x-2 justify-end">
				<button type="button" class="backButton" style={{ width: '8%' }} onClick={() => mainPage()}>
					Back
				</button>
			</div>

			<form>
				<div class="grid xl:grid-cols-3  m:gap-2">
					<div class="relative z-0 p-2 w-full mb-6 group" style={{ marginTop: 2 }}>
						<label for="classification" class="form-label inline-block mb-2 text-gray-700">
							Classification
						</label>
						<select
							class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							aria-label="Default select example"
							name="classification"
							onChange={(e) => setClassification(e.target.value)}>
							<option>-- Select Type --</option>
							<option value="WHOLESALER">Wholesaler</option>
							<option value="RETAILER">Retailer</option>
							<option value="MANUFACTURER">Manufacturer</option>
						</select>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group" style={{ marginTop: 2 }}>
						<label for="domainType" class="form-label inline-block mb-2 text-gray-700">
							Domain Type
						</label>
						<select
							class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							aria-label="Default select example"
							name="domainType"
							onChange={(e) => setDomainType(e.target.value)}>
							<option>-- Select Type --</option>
							{types.map((typeget) => <option value={typeget._id}>{typeget.domainType}</option>)}
						</select>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group" style={{ marginTop: 2 }}>
						<label for="distributor" class="form-label inline-block mb-2 text-gray-700">
							Distributors
						</label>
						<select
							class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							aria-label="Default select example"
							name="distributor"
							onChange={(e) => setDistributor(e.target.value)}>
							<option>-- Select Type --</option>
							{tDatas.map((typeget) => (
								<option value={typeget._id}>
									{typeget.firstName} {''}
									{typeget.lastName} {'--'} {''}
									{typeget.pincode}
								</option>
							))}
						</select>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>First Name</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="firstName"
							value={data.firstName}
							onChange={handleChange}
							placeholder="Enter First Name"
						/>
					</div>
					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Last Name</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="lastName"
							value={data.lastName}
							onChange={handleChange}
							placeholder="Enter Last Name"
						/>
					</div>

					{/* <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 2 }}
          >
            <label
              for="gender"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Gender
            </label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="classification"
              onChange={(e) => setGender(e.target.value)}
            >
              <option>-- Select Type --</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div> */}

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Firm Name</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="firmName"
							value={data.firmName}
							onChange={handleChange}
							placeholder="Enter Firm Name"
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Pin Code</label>
						<Pincode
							// getData={(data) => setPincodeData(data)}
							pincodeInput={{
								width: '100%',
								height: 35,
								border: '2px solid grey',
								borderRadius: 5,
								paddingLeft: '12px'
							}}
							showArea={false}
							showCity={false}
							showState={false}
							showDistrict={false}
							getData={(data) => setPincodeData(data)}
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Email Address</label>
						<input
							type="email"
							class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="email"
							id="email"
							value={data.email}
							onChange={handleChange}
							placeholder="Enter Email Address"
							required
						/>
						<p class="invisible peer-invalid:visible text-red-700 font-light">
							Please enter your email address
						</p>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Mobile No:</label>
						<input
							type="number"
							class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="mobile"
							id="mobile"
							value={data.mobile}
							onChange={handleChange}
							placeholder="Enter Mobile No"
							required
							onWheel={(e) => e.target.blur()}
							onInput={(e) => {
								e.target.value = e.target.value.toString().slice(0, 10);
							}}
						/>
						{mobile == 10 ? (
							<p class="peer-invalid:visible text-green-700 font-light">
								You have 10 digit mobile number
							</p>
						) : (
							<p class="peer-invalid:visible text-red-700 font-light">
								Please Enter Your Valid Mobile Number
							</p>
						)}
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>City</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="city"
							name="city"
							value={pincodeData.city}
							placeholder="Enter Your City"
							disabled
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>District</label>
						<input
							type="text"
							class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="district"
							name="district"
							value={pincodeData.district}
							placeholder="Enter Your District"
							disabled
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>State</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="state"
							name="stateName"
							value={pincodeData.stateName}
							placeholder="Enter Your State"
							disabled
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Country</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="country"
							name="country"
							value={country}
							placeholder="Enter Your Country"
							disabled
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Address</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="address"
							value={data.address}
							onChange={handleChange}
							placeholder="Enter Address"
						/>
					</div>

					{/* <div class="relative z-0 p-2 w-full mb-6 group">
						<label>Country</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="country"
							value={data.country}
							onChange={handleChange}
							placeholder="Enter Country"
						/>
					</div> */}

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Aadhaar Card</label>
						<input
							type="number"
							class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="addharCard"
							name="addharCard"
							value={data.addharCard}
							onChange={handleChange}
							placeholder="Enter Aadhaar Card Number"
							onWheel={(e) => e.target.blur()}
							onInput={(e) => {
								e.target.value = e.target.value.toString().slice(0, 12);
							}}
						/>
						{aadharCard == 12 ? (
							<p class="peer-invalid:visible text-green-700 font-light">
								Now you have a Valid AdharCard Number
							</p>
						) : (
							<p class="peer-invalid:visible text-red-700 font-light">
								Please Enter Valid AdharCard Number
							</p>
						)}
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Pan Card</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="panCard"
							name="panCard"
							value={data.panCard}
							// onChange={handleChange}
							onChange={(e) => {
								const { name, value } = e.target;
								// Allow only alphanumeric characters and limit to 10
								const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 10);
								handleChange({ target: { name, value: sanitizedValue } });
							  }}
							placeholder="Enter Pan Card Number"
							onInput={(e) => {
								e.target.value = e.target.value.toString().slice(0, 10);
							}}
						/>
						{data.panCard && /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(data.panCard) ? (
    <p class="text-green-700 font-light">Now you have a valid PAN card number</p>
  ) : (
    <p class="text-red-700 font-light">Please enter a valid PAN card number</p>
  )}
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Password</label>
						<input
							type="password"
							class="form-control peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="password"
							name="password"
							value={data.password}
							onChange={handleChange}
							placeholder="Enter Password"
							required
						/>
						{password >= 6 ? (
							<p class="peer-invalid:visible text-green-700 font-light">Now you have a Valid Password</p>
						) : (
							<p class="peer-invalid:visible text-red-700 font-light">
								Please Enter Atleast 6 Digit Password
							</p>
						)}
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Confirm Password</label>
						<input
							type="password"
							class="form-control  peer border border-slate-400
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							id="confirmpassword"
							name="confirmpassword"
							placeholder="Repeat Password"
							value={data.confirmpassword}
							onChange={handleChange}
							required
						/>

						{data.confirmpassword &&
						data.password === data.confirmpassword && (
							<p class="peer-invalid:visible text-green-700 font-light">Password do match</p>
						)}
						{data.password !== data.confirmpassword && (
							<p class="peer-invalid:visible text-red-700 font-light">Password do not match</p>
						)}
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Bank Name</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="bankName"
							value={data.bankName}
							onChange={handleChange}
							placeholder="Enter Bank Name"
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group" style={{ marginTop: 2 }}>
						<label>Account Type</label>
						<select
							class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							aria-label="Default select example"
							name="accountType"
							onChange={(e) => setAccountType(e.target.value)}>
							<option>-- Select Type --</option>
							<option value="SAVING">SAVING</option>
							<option value="CURRENT">CURRENT</option>
						</select>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Account No</label>
						<input
							type="number"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="accountNo"
							value={data.accountNo}
							onChange={handleChange}
							placeholder="Enter Account No"
							onWheel={(e) => e.target.blur()}
							maxLength={12}
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>Branch Name</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="branchName"
							value={data.branchName}
							onChange={handleChange}
							placeholder="Enter Branch Name"
						/>
					</div>

					<div class="relative z-0 p-2 w-full mb-6 group">
						<label>IFSC Code</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="ifscCode"
							value={data.ifscCode}
							onChange={handleChange}
							placeholder="Enter IFSC Code"
						/>
						 {/^[A-Z]{4}0[A-Z0-9]{6}$/.test(data.ifscCode) ? (
          <p class="text-green-700 font-light">Valid IFSC Code</p>
        ) : (
          <p class="text-red-700 font-light">Please enter a valid IFSC code</p>
        )}
					</div>

					<div>
						<label>Image</label>
						<div class="relative mt-1 flex justify-center w-40">
							<div class=" absolute right-0 top-0 z-10 flex">
								<label
									for="file-upload"
									class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
									<BsFillPencilFill />
									<input
										id="file-upload"
										name="file-upload"
										disabled={selectedImages.length == 1}
										accept="image/*"
										type="file"
										onChange={onSelectFile}
										class="sr-only"
									/>
								</label>
							</div>
							<div class="relative z-0 p-2 w-full mb-6 group">
								{selectedImages.length > 0 ? (
									selectedImages.map((item, index) => {
										return (
											<div style={{ display: 'inline-block' }} key={item}>
												<p className="block-icon">
													<img src={URL.createObjectURL(item)} alt="img" />
													<ImCross
														style={{ cursor: 'pointer' }}
														onClick={() => deleteHandler(item)}
														className="icon-belowtag"
													/>
												</p>
											</div>
										);
									})
								) : (
									<div>
										<img src={blank} alt="user-profile" />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</form>
			<div class="flex space-x-2 justify-end mr-2">
				<button type="button" style={{ width: '8%' }} onClick={postData} class="addButton">
					Add
				</button>
			</div>
		</div>
	);
};

export default AddMerchant;
