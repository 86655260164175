


import { Alert, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
// import { showErrorMsg } from "../../components/helper/message";
import "../view.css";

const ViewEmployee = () => {
  const navigate = useNavigate();
  const { employeeInfo, viewData } = useContext(StateContext);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
console.log(employeeInfo, "employees employees")
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
	const [isSubmitted, setIsSubmitted] = useState(false)
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg", "png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      //     alert("Only jpg/jpeg and png files are allowed!");
      setMsg(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      timeout = displayErrMsg(setErrMsg, timeout);
      setLoading(false);
      return false;
    } else {
      setSelectedImages((previousImages) =>
        previousImages.concat(selectedFiles)
      );
      event.target.value = "";
    }
    // const selectedFiles = event.target.files[0];
    // setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const mainPage = () => {
    navigate("/employees");
  };
  return (
    <div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
     
      <h4 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        View Empolyee
      </h4>
      <div className="flex space-x-2 justify-end">
        <button type="button" className="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>
      <form>
        <div className="grid xl:grid-cols-3  m:gap-2">
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="firstName"
              onChange={(e) => setFName(e.target.value)}
              value={employeeInfo.firstName}
              placeholder="Enter First Name"
            />
			{isSubmitted && fName.length === 0 && <p className='text-red-400'>Enter first name</p>}
          </div>
      
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              name="lastName"
              value={employeeInfo.lastName}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Last Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Distributor Name</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              name="lastName"
              value={employeeInfo.distributorId?.firmName}
              placeholder="Enter firm Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Per Delivery Rate</label>
            <input required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="delivery"
              name="deliveryPerRate"
              value={employeeInfo.deliveryPerRate}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Per Delivery Rate"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Status</label>
            <select required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="status"
              name="status"
              value={employeeInfo.status}
              onChange={(e) => setFName(e.target.value)}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Status
              </option>
              <option value="FREE">Free</option>
              <option value="BUSY">Busy</option>
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile Number</label>
            <input required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobile"
              name="mobile"
              value={employeeInfo.mobile}
              placeholder="Enter Mobile Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Email ID</label>
            <input required
              type="email"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              value={employeeInfo.email}
              placeholder="Enter Email Address"
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
        <label
          for="name"
          class="form-label inline-block mb-2 text-gray-700"
        >
          Password
        </label>
        <input
          type="text"
          class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="password"
          name="password"
          value={employeeInfo.password}
          onChange={(e) => setFName(e.target.value)}
          placeholder=" Enter Password"
        />
      </div> */}

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gender"
              name="gender"
              value={employeeInfo.gender}
              onChange={(e) => setFName(e.target.value)}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Gender
              </option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Pin Code</label>
            <input required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pincode"
              name="pincode"
              value={employeeInfo.pincode}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Pincode"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              value={employeeInfo.address}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Full Address"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              value={employeeInfo.city}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter City Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="state"
              value={employeeInfo.state}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter State Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"
              value={employeeInfo.country}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Country Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Aadhar Card</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="adharcard"
              name="addharCard"
              value={employeeInfo.addharCard}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Aadhar Card Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pancard"
              name="panCard"
              value={employeeInfo.panCard}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Pan Card Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankname"
              name="bankName"
              value={employeeInfo.bankName}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Bank Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchname"
              name="branchName"
              value={employeeInfo.branchName}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Branch Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Account Type</label>
            <select required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="acctype"
              name="bankAccountType"
              value={employeeInfo.bankAccountType}
              onChange={(e) => setFName(e.target.value)}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Account Type
              </option>
              <option value="SAVING">Saving Account</option>
              <option value="CURRENT">Current Account</option>
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Account Number</label>
            <input required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accnumber"
              name="accountNo"
              value={employeeInfo.accountNo}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter Account Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifsccode"
              name="ifscCode"
              value={employeeInfo.ifscCode}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter IFSC Code"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Type</label>
            <select required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="employee"
              name="empStatus"
              value={employeeInfo.empStatus}
              onChange={(e) => setFName(e.target.value)}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Employee Type
              </option>
              <option value="SELF">Self</option>
              <option value="OTHER">Individual</option>
            </select>
          </div>
          <br />
          <div>
            <label style={{ paddingLeft: "12px" }}>Image</label>
            <div className="relative mt-1 flex justify-center w-40">
              {/* <div className=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input required
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    className="sr-only"
                  />
                </label>
              </div> */}
         
              <div className=" relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          {/* <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          /> */}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={employeeInfo.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
			{console.log(employeeInfo, "employeeInfo")}
          </div>
          {/* <div class="relative z-0 p-2 w-full mb-6 group">
        <label>Active/Inactive Status</label>
        <select
          class="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="activeinactive"
           name="activeinactive" 
          onChange={(e) => setFName(e.target.value)} 
          value = {addData.ActiveInactive}      
  aria-label="Default select example"
        >
          <option value="">Select Active/Inactive</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div> */}
        </div>
        {/* <button className="addButton">Update</button> */}
      </form>
      {/* <div class="flex space-x-2 justify-end">
    <button 
      type="button"  
      class="addButton"  
      onClick={handleSubmit} >
      Update
    </button>
  </div> */}
    </div>
  );
};

export default ViewEmployee;




// import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';
// import { AiOutlineRollback } from 'react-icons/ai';
// import '../../pages/view.css';
// import StateContext from '../../contexts/ContextProvider';

// const ViewEmployee = () => {
// 	const { employeeInfo } = useContext(StateContext);
// 	return (
// 		<div class="container">
// 			<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
// 				<div class="flex justify-end active">
// 					<div class="backIcon">
// 						<Link to="/employees">
// 							<AiOutlineRollback />
// 						</Link>
// 					</div>
// 				</div>
// 				<div class="flex justify-center">
// 					<ul class="bg-white rounded-lg w-96 text-gray-900">
// 						<li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
// 							First Name : {employeeInfo.firstName}
// 						</li>
// 						<li class="px-6 py-2 border-b border-gray-200 w-full">Last Name : {employeeInfo.lastName}</li>
// 						<li class="px-6 py-2 border-b border-gray-200 w-full">Pin Code : {employeeInfo.pincode}</li>
// 						<li class="px-6 py-2 border-b border-gray-200 w-full">Mobile No : {employeeInfo.mobile}</li>
// 						<li class="px-6 py-2 w-full rounded-b-lg">Email : {employeeInfo.email}</li>
// 					</ul>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default ViewEmployee;
