import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "../view.css";
import axios from "axios";

const AddDomainType = () => {
  const [types, setTypes] = useState([]);
  const [data, setData] = useState([""]);

  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/domainType");
  };

  const getTypes = async () => {
    const res = await axios.get(`https://api.orbitmart.co.in/api/v1/merchant/Type`);
    setTypes(res.data.data);
    console.log(res.data.data);
  };

  console.log(types);

  useEffect(() => {
    getTypes();
  }, []);

  console.log(data);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Domain Type
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button type="button" class="addButton" style={{ width: "8%" }}>
          Add
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="domainType"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Domain Type
            </label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="domainType"
              onChange={(e) => setData(e.target.value)}
            >
              <option>-- Select Type --</option>
              {types.map((typeget) => (
                <option value={typeget.domainType} key={typeget._id}>
                  {typeget.domainType}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDomainType;
