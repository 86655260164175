import React from "react";
import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { RiErrorWarningLine } from "react-icons/ri";

import { Link } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { render } from "react-dom";
import { Modal } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "52.5%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 13,
  width: 547,
  height: 420,
  borderRadius: "35px",
};

const AssignPopup = ({id}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {orderData, setOrderData} = useContext(StateContext)
    const [employee, setEmployee] = useState([]);
    const [filteremployee, setfilteremployee] = useState([]);
    const [emptype, setEmptype] = useState("");
    const [empId, setEmpId] = useState("");
    console.log(id?.distributorId?._id, "iddfv", id)

    const handleemptype = (e) => {
      console.log(e.target.value,"idddd target")
      setEmptype(e.target.value);
      filterdata(e.target.value);
    };
    const filterdata = (data) => {
      if (data == "SELF") {
        let data = employee?.filter(
          (item) => item?.empStatus.toUpperCase() == "SELF"
        );
        setfilteremployee(data);
      } else if (data !== "SELF") {
        let data = employee.filter(
          (item) => item?.empStatus.toUpperCase() !== "SELF"
        );
        setfilteremployee(data);
      }
    };
    const handleemp = (e) => {
      setEmpId(e.target.value);
    };
  
    const fetchEmployee = async () => {
      try {
        // let id = sessionStorage.getItem("id");
        // console.log("IDName", id);
      
        const response = await axios.get(`https://api.orbitmart.co.in/api/v1/auth/employee/byDistributorId/${id?.distributorId?._id}`);
        const data = response?.data?.data;
        setEmployee(data);
        console.log(data,"employ");
      
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    
    console.log("id.distributorId", id.distributorId, filteremployee, employee)

    
    const navigate = useNavigate();
    const mainPage = () => {
        navigate("/Orders");
      };
      const API = "https://api.orbitmart.co.in/api/v1/order";
    
      const handleAssign = async () => {
        const payload = {
          orderStatus: "PACKAGING",
          employeeId: empId,
          isEmployee: true,
        };
      
    
        // add token
    
        const config = {
          headers: {
            "Content-Type": "application/json",
            "x-token": sessionStorage.getItem("token"),
          },
        };
        await axios
          .put(`${API}/${id._id}`, payload, config)
          .then(async(response) => {
            const SuccessRes = response.data.status == "SUCCESS";
            if (SuccessRes) {

                await axios
       
                .get(`https://api.orbitmart.co.in/api/v1/order`)
                .then((res) => {
                  let data=res.data.data
                  .filter((or) => or.distributorId == sessionStorage.getItem("id"))
                  .reverse().map((itemId)=>{ itemId.grandTotal=getAmount(itemId);return itemId})
      
                  setOrderData(data);
              
                })
                .catch((err) => {
                  console.log("orderdata error",err.message);
                });
              console.log("handleAssign response", response);
              mainPage();
            }
          })
          .catch((error) => {
            console.log("handleAssign response error!", error);
          });
      };

      
      useEffect(() => {
         fetchEmployee();
      }, []);
    

      const getAmount = (itemId) => {
        const subtotal = itemId?.productDetails
          ?.map((pro) => {
            return pro.productId?.itemPrice * pro?.quantity;
          })
          .reduce((a, b) => a + b)||0;
        const customerDiscount = itemId?.productDetails
          ?.map((pr) => {
            if (pr?.productId?.discountType == "FIXED") {
              return parseFloat(pr?.productId?.customerDiscount);
            } else {
              return (
                (parseFloat(pr?.productId?.itemPrice) *
                  parseFloat(pr?.quantity) *
                  parseFloat(pr?.productId?.customerDiscount)) /
                100
              );
            }
          })
          .reduce((a, b) => a + b)||0;
        const GstLogic =
          ((subtotal - customerDiscount) *
            parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
          100||0;
        const DeliveryAmount = 0;
        const TotalAmount = (
          subtotal -
          customerDiscount +
          GstLogic +
          DeliveryAmount
        ).toFixed(0);
        return TotalAmount;
      };
    
    
      return (
        <div>
          <button
            type="button"
            style={{ marginTop: 1, alignItems: "center" }}
            onClick={handleOpen}
          >
            ASSIGN
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            //hideBackdrop={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <div id="EmplyDitels">
                  <h4>Assign Employee to Order :</h4>
                </div>
                <div className="EmplayBox">
                  <div className="EmplayType">
                    <label className="lablestyle">
                      Employee Type
                      <span>*</span>
                    </label>
                    <select
                      aria-label="Default select example"
                      name="categoryId"
                      value={emptype}
                      onChange={handleemptype}
                      required
                      className="EmplayeInput"
                    >
                      <option selected>Select Value</option>
                      {/* {employee.map((typeget) => (
                        <option value={typeget.empStatus} id={typeget._id}>
                          {typeget.empStatus}
                        </option>
                      ))} */}
                        <option value="SELF">Self</option>
              <option value="Individual">Individual</option>
                    </select>
                  </div>
                  <br />
    
                  <div className="EmplayeDetails">
                    <label className="lablestyle1">
                      Employees
                      <span>*</span>
                    </label>
                    <select
                      aria-label="Default select example"
                      name="categoryId"
                      value={empId}
                      onChange={handleemp}
                      required
                      className="EmplayeDetailsInput"
                    >
                      <option selected>Select Value</option>

                      {filteremployee
                      .map((employee) => (
                        <option value={employee?._id} id={employee?._id}>
                          {employee?.firstName} &nbsp;
                          {employee?.lastName} &nbsp; &nbsp;
                          {employee?.status}
                        </option>
                      ))}
                    </select>
                  </div>
    
                  <div className="ButtonList">
                    <div className="AssignButton">
                      <button
                        type="button"
                        onClick={() => handleAssign()}
                    
                    
                      >
                        Assign
                      </button>
                    </div>
    
                    <div className="backButton1">
                      <button
                        type="button"
                        onClick={handleClose}
                      >
                        Cancel 
                      </button>
                    </div>
    
                    {/* <div className="RegectButton">
                      <button
                        type="button"
                        onClick={() => handleAssign()}
                       
                    
                      >
                        Regect
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      );
}

export default AssignPopup