import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import blank from "../../assets/blank.jpg";
import { BsFillPencilFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { setValue } from "@syncfusion/ej2/base";
import { Alert, Stack } from "@mui/material";
//Toast
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const AddMerchantType = () => {
  const [domainType, setDomainType] = useState("");
  const [merchantType, setMerchantType] = useState("");

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  //Image

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  // const notify = () => {
  //   toast.success("Data Added Successfully", {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  // };

  const mainPage = () => {
    navigate("/merchantType");
  };

  console.log(selectedImages, "selectedImages")

  function postData() {
    if(domainType==""||merchantType=="" || selectedImages.length === 0)
    {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
    }
    else{

  
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = "https://api.orbitmart.co.in/api/v1/merchant/Type";
    const formData = new FormData();
    const imageFinal = selectedImages[0];
    formData.append("Image", imageFinal);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        debugger;
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          domainType: domainType,
          merchantType: merchantType,
          image: coverPhoto,
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then((res) => {
            console.log(res);
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 1000);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
    }
  }

  // async function postData() {
  //   const addData = {
  //     domainType: domainType,
  //     merchantType: merchantType,
  //   };
  //   console.log(addData);
  //   debugger;
  //   try {
  //     const res = await fetch("https://api.orbitmart.co.in/api/v1/merchant/Type", {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-access-token": "token-value",
  //       },
  //       body: JSON.stringify(addData),
  //     });
  //     if (!res.ok) {
  //       const message = `An error has occured: ${res.status} - ${res.statusText}`;
  //       throw new Error(message);
  //     }
  //     const data = await res.json();
  //     const result = {
  //       status: res.status + "-" + res.statusText,
  //       headers: {
  //         "Content-Type": res.headers.get("Content-Type"),
  //         "Content-Length": res.headers.get("Content-Length"),
  //       },
  //       data: data,
  //     };
  //     console.log(data);
  //     console.log(result);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  //   mainPage();
  // }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Add Merchant Type
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          style={{ width: "8%" }}
          class="addButton"
          onClick={postData}
        >
          Add
        </button>
      </div>

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Merchant Type</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="merchantType"
              onChange={(e) => setMerchantType(e.target.value)}
              placeholder="Enter Type"
            >
              <option selected>Select Value</option>
              <option value="RETAILER">RETAILER</option>
              <option value="WHOLESALER">WHOLESALER</option>
              <option value="MANUFACTURER">MANUFACTURER</option>
            </select>
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Domain Type</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="domainType"
              onChange={(e) => setDomainType(e.target.value)}
              placeholder="Enter Domain"
            />
          </div>

          <div>
            <label>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddMerchantType;
