import * as React from "react";
import "../view.css";
import { useNavigate } from "react-router-dom";

const AddAdmin = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/");
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Admin
      </h4>
      <div class="flex space-x-2 justify-end">
        <button type="button" class="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="firstname"
              class="form-label inline-block mb-2 text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              placeholder="First Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="lastname"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              placeholder="Last Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="Email"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Email"
              placeholder="Email"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="password"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="password"
              placeholder="Password"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="mobileno"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Mobile Number
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobileno"
              placeholder="Mobile Number"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="address"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Address
            </label>
            <textarea
              id="address"
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Address"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="state"
              class="form-label inline-block mb-2 text-gray-700"
            >
              State
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              placeholder="State"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="city"
              class="form-label inline-block mb-2 text-gray-700"
            >
              City
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              placeholder="City"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="zipcode"
              class="form-label inline-block mb-2 text-gray-700"
            >
              ZipCode
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="zipcode"
              placeholder="Zipcode"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="country"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Country
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              placeholder="Enter Country"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="gender"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Gender
            </label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="male"
              />
              <label
                class="form-check-label inline-block text-gray-800"
                for="inlineRadio10"
              >
                Male
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  mr-2 cursor-pointer"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="female"
              />
              <label
                class="form-check-label inline-block text-gray-800"
                for="inlineRadio20"
              >
                Female
              </label>
            </div>
          </div>
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button type="button" class="addButton">
          Add
        </button>
      </div>
    </div>
  );
};

export default AddAdmin;
