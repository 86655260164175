import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StateContext from '../../contexts/ContextProvider';
import { Link } from 'react-router-dom';
import axios from '../api/axiosapi';
import { WithContext as ReactTags } from 'react-tag-input';


const ViewDistributor = () => {
	const navigate = useNavigate();

	const mainPage = () => {
		navigate('/distributors');
	};

	const { viewData } = useContext(StateContext);
	console.log(viewData.subPincode);

	const { id } = useParams();
	console.log(id);

	const [ updatedValue, setUpdatedValue ] = useState([ '' ]);

	const [ selectedImages, setSelectedImages ] = useState([]);

	const onSelectFile = (event) => {
		// const selectedFiles = event.target.files;
		const selectedFiles = event.target.files[0];
		// const selectedFilesArray = Array.from(selectedFiles);

		// const imagesArray = selectedFilesArray.map((file) => {
		//   return file;
		// });

		setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
		// setSelectedImages((previousImages) => previousImages.concat(imagesArray));

		// FOR BUG IN CHROME
		event.target.value = '';
	};

	function deleteHandler(image) {
		setSelectedImages(selectedImages.filter((e) => e !== image));
		URL.revokeObjectURL(image);
	}

	function fetchData() {
		axios
			.get(`https://api.orbitmart.co.in/api/v1/auth/byDistributor/${id}`)
			.then((res) => {
				console.log(res.data);
				setUpdatedValue(res.data.data);
			})
			.catch((err) => {
				console.log(err.message);
			});
	}

	useEffect(() => {
		fetchData();
	}, []);

	
	const [tags, setTags] = useState([]);

  
	const handleDelete = i => {
	  setTags(tags.filter((tag, index) => index !== i));
	};
  
	const handleAddition = tag => {
	  setTags([...tags, tag]);
	};
  
	const handleTagClick = index => {
	  console.log('The tag at index ' + index + ' was clicked');
	};
  
	const NUMBER = 6;
	const MAXTAGS = 3;
	return (
		<div>
			<div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
				<h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">View Distributor</h4>
				<div class="flex space-x-2 justify-end">
					<button style={{ width: '8%' }} type="button" class="backButton" onClick={() => mainPage()}>
						Back
					</button>
				</div>
				<form>
					<div class="grid xl:grid-cols-3  m:gap-2">
						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>First Name</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								id="firstName"
								name="firstName"
								value={updatedValue.firstName}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Last Name</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								id="lastName"
								name="lastName"
								value={updatedValue.lastName}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Pin Code</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								id="pincode"
								name="pincode"
								value={updatedValue.pincode}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Address</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								id="address"
								name="address"
								value={updatedValue.address}
								disabled
							/>
						</div>

						{/* <div class="relative z-0 p-2 w-full mb-6 group">
              <label
                for="gender"
                class="form-label inline-block mb-2 text-gray-700"
              >
                Gender
              </label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="gender"
                name="gender"
                value={updatedValue.gender}
                onChange={handleChange}
              />
            </div> */}

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Firm Name</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								id="firmName"
								name="firmName"
								value={updatedValue.firmName}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>State</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								id="state"
								name="state"
								value={updatedValue.state}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Email</label>
							<input
								type="email"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								id="email"
								name="email"
								value={updatedValue.email}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Country</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="country"
								value={updatedValue.country}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>City</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="city"
								value={updatedValue.city}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>District</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="district"
								value={updatedValue.district}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Aadhaar Card</label>
							<input
								type="number"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="addharCard"
								value={updatedValue.addharCard}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Pan Card</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="panCard"
								value={updatedValue.panCard}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Mobile No</label>
							<input
								type="number"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="mobile"
								value={updatedValue.mobile}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Bank Name</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="bankName"
								value={updatedValue.bankName}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Account Type</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="bankAccountType"
								value={updatedValue.bankAccountType}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Account No</label>
							<input
								type="number"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="accountNo"
								value={updatedValue.accountNo}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Branch Name</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="branchName"
								value={updatedValue.branchName}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Ifsc Code</label>
							<input
								type="text"
								class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								name="ifscCode"
								value={updatedValue.ifscCode}
								disabled
							/>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
            <label>Additional Pin Code</label>
            <ReactTags
			classNames={{
				tags: 'tagsClass',
				tagInput: 'tagInputClass',
				tagInputField: 'tagInputFieldClass',
				selected: 'selectedClass',
				tag: 'tagClass',
				remove: 'removeClass',
				suggestions: 'suggestionsClass',
				activeSuggestion: 'activeSuggestionClass',
				editTagInput: 'editTagInputClass',
				editTagInputField: 'editTagInputField',
				clearAll: 'clearAllClass',
			  }}
              tags={viewData?.subPincode?.map(pin => ({id: pin, text: pin}))}
			  readOnly={true}
			//   maxTags= {MAXTAGS}
            //   suggestions={suggestions}
            //   delimiters={delimiters}
			placeholder= "Add Pin"
			inline
			// inputFieldPosition="inline"
			maxLength = {NUMBER}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
            //   handleDrag={handleDrag}
              handleTagClick={handleTagClick}
            //   autocomplete
            />
          </div>

						<div style={{ marginLeft: 20 }}>
							<label>Image</label>
							<div class="relative mt-1 flex justify-center w-40">
								<div class=" absolute right-0 top-0 z-10 flex">
									<label
										for="file-upload"
										class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
										<input
											id="file-upload"
											name="file-upload"
											disabled={selectedImages.length == 1}
											accept="image/*"
											type="file"
											onChange={onSelectFile}
											class="sr-only"
										/>
									</label>
								</div>
								<div class="relative z-0 p-2 w-full mb-6 group">
									{selectedImages.length > 0 ? (
										selectedImages.map((item, index) => {
											return (
												<div style={{ display: 'inline-block' }} key={item}>
													<p className="block-icon">
														<img src={URL.createObjectURL(item)} alt="img" />
														<ImCross
															style={{ cursor: 'pointer' }}
															onClick={() => deleteHandler(item)}
															className="icon-belowtag"
														/>
													</p>
												</div>
											);
										})
									) : (
										<div>
											<img src={updatedValue.avatar} alt="user-profile" />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ViewDistributor;
