import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert, Stack } from '@mui/material';
import { API } from '../../../config/api';

const AddUnitQuantity = () => {
	const [ data, setData ] = useState([ '' ]);
	const [ alert, setAlert ] = useState(false);
	const [ error, setError ] = useState(false);

	const navigate = useNavigate();

	const mainPage = () => {
		navigate('/unitQuantity');
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
	};

	async function postData() {
		if (!data.title) {
			setError(true);
			setTimeout(() => {
				setError(false);
			}, 1000);
		} else {
			const finalData = {
				title: data.title
			};
			try {
				const res = await fetch(`${API}/unitQuantity`, {
					method: 'post',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(finalData)
				});
				if (!res.ok) {
					setError(true);
					setTimeout(() => {
						mainPage();
					}, 1000);
					const message = `An error has occured: ${res.status} - ${res.statusText}`;
					throw new Error(message);
				}
				const data = await res.json();
				const result = {
					status: res.status + '-' + res.statusText,
					headers: {
						'Content-Type': res.headers.get('Content-Type'),
						'Content-Length': res.headers.get('Content-Length')
					},
					data: data
				};
				console.log(data);
				console.log(result);
				setAlert(true);
				setTimeout(() => {
					mainPage();
				}, 1000);
			} catch (error) {
				setError(true);
				setTimeout(() => {
					mainPage();
				}, 1000);
				console.log(error.message);
			}
		}
	}

	return (
		<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
			{alert ? (
				<Stack
					sx={{ width: '30%' }}
					style={{ position: 'fixed', left: '22%', bottom: '1%', width: '30%' }}
					spacing={2}>
					<Alert variant="filled" severity="success">
						Data Added Successfully
					</Alert>
				</Stack>
			) : null}
			{error ? (
				<Stack
					sx={{ width: '30%' }}
					style={{ position: 'fixed', left: '22%', bottom: '1%', width: '30%' }}
					spacing={2}>
					<Alert variant="filled" severity="error">
						Sorry, Data Cannot be Added at this moment. Please try Again!
					</Alert>
				</Stack>
			) : null}
			<h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">Add Unit Quantity</h4>
			<div class="flex space-x-2 justify-end">
				<button type="button" class="backButton" style={{ width: '8%' }} onClick={() => mainPage()}>
					Back
				</button>
				<button type="button" style={{ width: '8%' }} class="addButton" onClick={postData}>
					Add
				</button>
			</div>
			<form>
				<div class="grid xl:grid-cols-3  m:gap-2">
					<div class="relative z-0 p-2 w-full mb-6 group">
						<label className='py-2'>unitQuantity</label>
						<input
							type="text"
							class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
							name="title"
							value={data.title}
							onChange={handleChange}
							placeholder="Enter Unit Quantity"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default AddUnitQuantity;
