import React from 'react';
import { contextMenuItems, merchantsGrid, merchantsData } from './config/MerchantConfig';
import '../css/view.css';
import { Header } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsFillPencilFill } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import DeleteModal from './DeleteModal';
import { useContext } from 'react';
import StateContext from '../../contexts/ContextProvider';
import axios from 'axios';
import {
	FirstPageOutlined,
	LastPageOutlined,
	NavigateBefore,
	NavigateNext,
  } from "@material-ui/icons";
import {
  AppBar,
  Divider,
  Fade,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";
import MerchantToggle from './MerchantToggle';
const useStyles = makeStyles((theme) => ({
	table: {
	  minWidth: 650,
	  borderCollapse: "collapse",
	},
	cell: {
	  padding: "8px 0px 8px 16px",
	  textAlign: "start",
	  // border: "1px solid " + theme.palette.divider,
	},
	cellRow: {
	  // border: "1px solid " + theme.palette.divider,
	  padding: "15px",
	  textAlign: "start",
	},
  }));

const Merchants = () => {
	const [ tDatas, setTDatas ] = useState([]);

	const { setViewData, viewData } = useContext(StateContext);
	const { setValue } = useContext(StateContext);
	const { value } = useContext(StateContext);
	const [searchQuery, setSearchQuery] = useState("");
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10);
	const random = 223;

	const fetchData = async() => {
		try {
			const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/allMerchant');
			const data = response.data.data;
			console.log(data, "data", viewData)
			setTDatas(data);

		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setCurrentPage(0); // Reset to the first page whenever a new search is made
	  };

	if (value == 200) {
		fetchData();
		setValue(random);
	}

	const itemId = tDatas.data;
	console.log(itemId);

	function gridActions(itemId) {
		return (
			<div className="flex items-center gap-2">
				<h1
					style={{ color: '#8be78b', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					 <Link style={{ marginRight: 'auto' }} to={`/merchants/edit`}> {/*merchants/edit/${itemId._id} */}
						<BsFillPencilFill onClick={() => itemEdit(itemId)} />
					</Link>
				</h1>
				<h1
					style={{ color: '#03c9d7', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<Link style={{ marginLeft: 'auto', marginRight: 'auto' }} to={`/merchants/view/${itemId._id}`}>
						<FiEye onClick={() => itemView(itemId)} />
					</Link>
				</h1>
				<h1
					style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<DeleteModal itemId={itemId._id} />
				</h1>
			</div>
		);
	}

	function gridStatus(merchantId) {
		let adminActiveInactive = merchantId.adminActiveInactive;
		return (
			<div class="flex items-center">
				<MerchantToggle tDatas={merchantId} adminActiveInactive={adminActiveInactive} />
			</div>
		);
	}


	const itemView = (itemId) => {
		setViewData(itemId);
		console.log(itemId)
	};

	const itemEdit = (itemId) => {
		setViewData(itemId);
	};

	const selectionsettings = { persistSelection: true };
	const toolbarOptions = [ 'Search' ];

	const editing = { allowDeleting: true, allowEditing: true };

	const navigate = useNavigate();

	const addPage = () => {
		navigate('/merchant/add');
	};

	const handleChangePage = (event, newPage) => {
		setCurrentPage(newPage);
	  };
	  const handleChangeRowsPerPage = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	  };
	  const changeToFirstPage = () => {
		setLoading(true);
		setCurrentPage(0);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToPrevPage = () => {
		setLoading(true);
		setCurrentPage(currentPage - 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToNextPage = () => {
		setLoading(true);
		setCurrentPage(currentPage + 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToLastPage = () => {
		setLoading(true);
		setCurrentPage(Math.ceil(tDatas?.length / pageSize) - 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };

	  console.log(tDatas, "tDatas")

	  const filteredData = tDatas.filter(item => 
		item?.firmName.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item.distributorId?.firmName.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item.pincode.includes(searchQuery) ||
		item.mobile.includes(searchQuery) ||
		item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item.pincode.toLowerCase().includes(searchQuery.toLowerCase())
	  );
	  const paginatedData = filteredData.slice(
		currentPage * pageSize,
		currentPage * pageSize + pageSize
	  );

	return (
		<div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
		  <div className="flex space-x-2 m-4 justify-end">
			<button type="button" onClick={() => addPage()} className="addBtn">
			  New Record
			</button>
		  </div>
		  <AppBar
			position="static"
			style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
		  >
			<Toolbar style={{ justifyContent: "space-between" }}>
			  <Typography variant="h6">Merchants ({tDatas?.length})</Typography>
			  <span
				className="flex space-x-2 justify-end"
				style={{ background: "white", borderRadius: "6px" }}
			  >
				<input
				  style={{
					padding: "4px 10px",
					outline: "none",
					borderRadius: "6px 0 0 6px",
					color: "black",
				  }}
				  type="text"
				  placeholder="Search..."
				  value={searchQuery}
				  onChange={handleSearchChange}
				/>
				{console.log(searchQuery)}
				<BiSearch
				  style={{
					fontSize: "25px",
					color: "gray",
					cursor: "pointer",
					margin: "auto",
					paddingRight: "5px",
				  }}
				/>{" "}
				{/*  this is the search icon */}
			  </span>
			</Toolbar>
		  </AppBar>
		  {/* {console.log({ filteredData })} */}
		  <div style={{ marginTop: "10px" }}></div>
		  <Divider />
		  <TableContainer>
			<Table>
			  <TableHead>
				<TableRow>
				  <TableCell className={classes.cellRow}>Merchant Name</TableCell>
				  <TableCell className={classes.cellRow}>Distributor</TableCell>
				  <TableCell className={classes.cellRow}>Pin Code</TableCell>
				  <TableCell className={classes.cellRow}>Mobile</TableCell>
				  <TableCell className={classes.cellRow}>Email</TableCell>
				  <TableCell className={classes.cellRow}>Status</TableCell>
				  <TableCell className={classes.cellRow} style={{textAlign: 'start'}}>Action</TableCell>
				</TableRow>
			  </TableHead>
			  <TableBody>
				{paginatedData.length > 0 ? (
				  paginatedData.map((item) => (
					<TableRow key={item._id}>
					  <TableCell
						className={classes.cell}>{`${item?.firmName}`}</TableCell>
					  <TableCell className={classes.cell}>{item.distributorId?.firmName}</TableCell>
					  <TableCell className={classes.cell}>{item.pincode}</TableCell>
					  <TableCell className={classes.cell}>{item.mobile}</TableCell>
					  <TableCell className={classes.cell}>{item.email}</TableCell>
					  <TableCell className={classes.cell}> {gridStatus(item)}</TableCell>
					  <TableCell className={classes.cell} style={{textAlign: 'start'}}>{gridActions(item)}</TableCell>
					</TableRow>
				  ))
				) : (
				  <TableRow>
					<TableCell colSpan={7} align="center">
					  No Data Available
					</TableCell>
				  </TableRow>
				)}
			  </TableBody>
			</Table>
			{paginatedData?.length > 0 ? (
			  <TablePagination
				count={tDatas?.length}
				component="div"
				page={currentPage}
				rowsPerPage={pageSize}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[10]}
				ActionsComponent={() => (
				  <div style={{ display: "flex", alignItems: "center" }}>
					<Button
					  onClick={changeToFirstPage}
					  disabled={currentPage === 0}
					>
					  <FirstPageOutlined />
					</Button>
					<Button onClick={changeToPrevPage} disabled={currentPage === 0}>
					  <NavigateBefore />
					</Button>
					<span className="myPage">{currentPage + 1}</span>
					<Button
					  onClick={changeToNextPage}
					  disabled={
						currentPage >= Math.ceil(tDatas?.length / pageSize) - 1
					  }
					>
					  <NavigateNext />
					</Button>
					<Button
					  onClick={changeToLastPage}
					  disabled={
						currentPage >= Math.ceil(tDatas?.length / pageSize) - 1
					  }
					>
					  <LastPageOutlined />
					</Button>
				  </div>
				)}
			  />
			) : null}
		  </TableContainer>
		</div>
	  );

	// return (
	// 	<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
	// 		<Header title="Merchants" />
	// 		{/* <Header category="Page" title="Merchants" /> */}
	// 		<div class="flex space-x-2 m-4 justify-end">
	// 			<button type="button" onClick={() => addPage()} className="addButton">
	// 				New Record
	// 			</button>
	// 		</div>
	// 		<GridComponent
	// 			id="gridcomp"
	// 			dataSource={tDatas.data}
	// 			width="auto"
	// 			allowPaging
	// 			allowSorting
	// 			allowExcelExport
	// 			allowPdfExport
	// 			pageSettings={{ pageCount: 5 }}
	// 			contextMenuItems={contextMenuItems}
	// 			selectionSettings={selectionsettings}
	// 			editSettings={editing}
	// 			toolbar={toolbarOptions}
	// 			allowFiltering={true}
	// 			// filterSettings={{ignoreAccent:true, type:'Menu'}}
	// 			// filterSettings={{ ignoreAccent: true, type: "CheckBox" }}
	// 			filterSettings={{ ignoreAccent: true, type: 'Excel' }}>
	// 			<ColumnsDirective>
	// 				{merchantsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
	// 				<ColumnDirective headerText="Actions" template={gridActions} textAlign="center" />
	// 			</ColumnsDirective>
	// 			<Inject
	// 				services={[
	// 					Resize,
	// 					Search,
	// 					ContextMenu,
	// 					Page,
	// 					Selection,
	// 					Toolbar,
	// 					Edit,
	// 					Sort,
	// 					Filter,
	// 					ExcelExport,
	// 					PdfExport
	// 				]}
	// 			/>
	// 		</GridComponent>
	// 	</div>
	// );
};
export default Merchants;
