import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { Link } from "react-router-dom";
import { Alert, Stack } from "@mui/material";
import axios from "../api/axiosapi";

const EditGroupCategory = () => {
  debugger;
  const navigate = useNavigate();
  const { data } = useContext(StateContext);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const mainPage = () => {
    navigate("/groupCategory");
  };

  const [updatedValue, setUpdatedValue] = useState(data);
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    debugger;
    const { name, value } = e.target;
    setValue({ ...value, [name]: value });
    setUpdatedValue({ ...updatedValue, [name]: value });
  };

  async function handleSubmit(e) {
    debugger;
    const editId = data._id;
    e.preventDefault();
    debugger;
    try {
      const res = await fetch(
        `https://api.orbitmart.co.in/api/v1/groupcategory/${editId}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": "token-value",
          },
          body: JSON.stringify(value),
        }
      );
      if (!res.ok) {
        setError(true);
        console.log(err);
        setTimeout(() => {
          mainPage();
        }, 1000);
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      const data = await res.json();
      const result = {
        status: res.status + "-" + res.statusText,
        headers: { "Content-Type": res.headers.get("Content-Type") },
        data: data,
      };
      console.log(result);
      setAlert(true);
      setTimeout(() => {
        mainPage();
      }, 1000);
    } catch (err) {
      setError(true);
      console.log(err);
      setTimeout(() => {
        mainPage();
      }, 1000);
      console.log(err.message);
    }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Updated Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Edit Group Category
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          class="addButton"
          onClick={handleSubmit}
          style={{ width: "8%" }}
        >
          Edit
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="address"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Group Category
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="title"
              value={updatedValue.title}
              onChange={handleChange}
              placeholder="Enter Classification"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditGroupCategory;
