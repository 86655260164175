import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { register } from '../../contexts/actions/register';
import RegisterUI from './RegisterUI';
import useForm from './useForm';

const RegisterContainer = () => {

  useEffect(() => {
  }, []);

  return (
    <RegisterUI form={useForm()} />
  )
}

export default RegisterContainer