import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { Link } from "react-router-dom";
import blank from "../../assets/blank.jpg";
import { BsFillPencilFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { Alert, Stack } from "@mui/material";
import axios from "../api/axiosapi";

const EditCategories = () => {
  const navigate = useNavigate();
  const { data } = useContext(StateContext);
  const mainPage = () => {
    navigate("/category");
  };

  const [updatedValue, setUpdatedValue] = useState(data);

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValue({ ...updatedValue, [name]: value });
  };
  // async function handleSubmit() {
  //   debugger;
  //   let id = data._id
  //   await axios
  //     .put(`https://api.orbitmart.co.in/api/v1/category/${id}`, {
  //     })
  //     .then((res) => {
  //       debugger;
  //       alert("its working");
  //     })
  //     .catch((error) => {
  //       alert("its not working");
  //     });
  // }

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  async function handleSubmit(e) {
    debugger;
    const editId = data._id;
    e.preventDefault();
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = `https://api.orbitmart.co.in/api/v1/category/${editId}`;
    const formData = new FormData();
    formData.append("Image", selectedImages[0]);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        debugger;
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          title: updatedValue.title,
          image: coverPhoto,
        };
        console.log(finalData);
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then(async (response) => {
            debugger;
            const isJson = response.headers
              .get("content-type")
              ?.includes("application/json");
            const data = isJson && (await response.json());
            console.log(data);
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          })
          .catch((err) => {
            setError(true);
            console.log(err);
            setTimeout(() => {
              mainPage();
            }, 1000);
          });
      })
      .catch((err) => {
        setError(true);
        console.log(err);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Updated Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Edit Category
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          class="addButton"
          onClick={handleSubmit}
          style={{ width: "8%" }}
        >
          Edit
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="address"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Category
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="title"
              value={updatedValue.title}
              onChange={handleChange}
              placeholder="Enter Category"
            />
          </div>

          <div>
            <label>Product Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={updatedValue.image} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EditCategories;
