import React, { useState, useEffect, useContext } from "react";
import { ImCross } from "react-icons/im";
import "../view.css";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Stack } from "@mui/material";

const EditUnitQuantity = () => {
  const { id } = useParams();
  console.log(id);

  const token = sessionStorage.getItem("token")
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  function mainPage() {
    navigate("/unit/Quantity");
  }

  const [updatedValue, setUpdatedValue] = useState([""]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.orbitmart.co.in/api/v1/unitPerQuantity/${id}`
      );
      setUpdatedValue(response.data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  console.log(updatedValue, "updatedValue")

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValue({ ...updatedValue, [name]: value });
  };

  async function handleSubmit(e) {
    const editId = id;
    e.preventDefault(); if (updatedValue.title.length === 0) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      throw new Error("Enter the unit quantity");
    } else {
      setError(false);
    }
    try {
      const res = await fetch(
        `https://api.orbitmart.co.in/api/v1/unitPerQuantity/${editId}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: JSON.stringify(updatedValue),
        }
      );
      if (!res.ok) {
        setError(true);
        console.log(err);
        setTimeout(() => {
          setError(false);
        }, 1000);
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      const data = await res.json();
      const result = {
        status: res.status + "-" + res.statusText,
        headers: { "Content-Type": res.headers.get("Content-Type") },
        data: data,
      };
      console.log(result);
      setAlert(true);
      setTimeout(() => {
        mainPage();
      }, 1000);
    } catch (err) {
      setError(true);
      console.log(err);
      setTimeout(() => {
        mainPage();
      }, 1000);
      console.log(err.message);
    }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Updated Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Edit Unit Quantity
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          class="addButton"
          style={{ width: "8%" }}
        >
          Update
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="title"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Unit Quantity
            </label>
            <input
              type="text"
              class="form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="title"
              value={updatedValue.title}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditUnitQuantity;
