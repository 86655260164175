import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../view.css";
import { Alert, Stack } from "@mui/material";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import blank from "../../assets/blank.jpg";
import Pincode from "react-pincode";
import StateContext from "../../contexts/ContextProvider";
// import { showErrorMsg } from "../../components/helper/message";

const AddEmployee = () => {
  const navigate = useNavigate();
  const initialState = {
    firstname: "",
    distributor: "",
    lastname: "",
    delivery: "",
    gender: "",
    address: "",
    pincode: "",
    mobile: "",
    email: "",
    password: "",
    country: "",
    state: "",
    city: "",
    employee: "",
    addharcard: "",
    panCard: "",
    bankdetails: "",
    branchname: "",
    accountno: "",
    accountype: "",
    ifscode: "",
    status: "",
    activeinactive: "",
  };
  const [addData, setAddData] = useState(initialState);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [pincode, setPincode] = useState(0);
  const [adharcard, setAdharCard] = useState(0);
  const [pancard, setPanCard] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [pincodeData, setPincodeData] = useState([]);
  const [country, setCountry] = useState("India");
  const {employees, distributor} = useContext(StateContext)
  // const [distributor, setDistributor] = useState([]);

  const token = sessionStorage.getItem("token");
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };

  const mainPage = () => {
    navigate("/employees");
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "password") {
  //     let length = document.getElementById("password").value.length;
  //     setPassword(length);
  //     setAddData({ ...addData, [name]: value });
  //   } else if (name === "mobile") {
  //     let length = document.getElementById("mobile").value.length;
  //     setMobile(length);
  //     setAddData({ ...addData, [name]: value });
  //   } else if (name === "pincode") {
  //     let length = document.getElementById("pincode").value.length;
  //     setPincode(length);
  //     setAddData({ ...addData, [name]: value });
  //   } else if (name === "panCard") {
  //     let length = document.getElementById("pancard").value.length;
  //     setPanCard(length);
  //     setAddData({ ...addData, [name]: value });
  //   } else if (name === "addharcard") {
  //     let length = document.getElementById("adharcard").value.length;
  //     setAdharCard(length);
  //     setAddData({ ...addData, [name]: value });
  //   } else {
  //     setAddData({ ...addData, [name]: value });
  //   }
  // };

  const handleChange = (e) => {
  const { name, value } = e.target;
  let length = value.length;

  // Handling specific cases for certain fields
  switch (name) {
    case "password":
      setPassword(length);
      break;
    case "mobile":
      setMobile(length);
      break;
    case "pincode":
      setPincode(length);
      break;
    case "panCard":
      setPanCard(length);
      break;
    case "addharcard":
      setAdharCard(length);
      break;
    default:
      break;
  }

  // Update the form data
  setAddData(prevAddData => ({
    ...prevAddData,
    [name]: value
  }));
};


  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg", "png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      //     alert("Only jpg/jpeg and png files are allowed!");
      setMsg(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      timeout = displayErrMsg(setErrMsg, timeout);
      setLoading(false);
      return false;
    } else {
      setSelectedImages((previousImages) =>
        previousImages.concat(selectedFiles)
      );
      event.target.value = "";
    }
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }


  async function handleSubmit() {
    if (
      !addData.password.trim() ||
      !addData.email.trim() ||
      !addData.mobile.trim() ||
      !addData.firstname.trim()
    ) {
      setMsg("All fields are required");
      timeout = displayErrMsg(setErrMsg, timeout);
      return false;
    } else {
      const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
      const uploadData =
        "https://api.orbitmart.co.in/api/v1/auth/admin/add/employee";
      const formData = new FormData();

      formData.append("image", selectedImages[0]);
      
      let isPending = true; // Track the pending status
      const pendingTimeout = setTimeout(() => {
        if (isPending) {
          setMsg("The request is taking too long. Please try again later.");
          setError(true);
        }
      }, 10000);
      try {
        const res = await axios.post(uploadImage, formData);
        const newimg = res.data.files;
        const coverPhoto = newimg;

        const finalData = {
          email: addData.email,
          mobile: addData.mobile,
          password: addData.password,
          firstName: addData.firstname,
          distributorId: addData.distributor,
          lastName: addData.lastname,
          deliveryPerRate: addData.delivery,
          state: pincodeData.stateName,
          city: pincodeData.city,
          district: pincodeData.district,
          pincode: pincodeData.pincode,
          country: country,
          UPI: addData.upi,
          gender: addData.gender,
          address: addData.address,
          addharCard: addData.addharcard,
          panCard: addData.panCard,
          bankName: addData.bankdetails,
          role: "EMPLOYEE",
          accountNo: addData.accountno,
          bankAccountType: addData.accountype,
          branchName: addData.branchname,
          ifscCode: addData.ifscode,
          empStatus: addData.employee,
          ActiveInactive: false,
          avatar: coverPhoto,
          verified: true,
        };

        console.log("hgg", finalData);

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: JSON.stringify(finalData),
        };

        const response = await fetch(uploadData, requestOptions);

        if (response.status === 201) {
          setError(false);
          setAlert(true);
          setTimeout(() => {
            mainPage();
          }, 1000);
        } else {
          setError(true);
          setAlert(false);
          setTimeout(() => {
            mainPage();
          }, 1000);
        }
      } catch (error) {
        setError(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      }
    }
  }

  console.log(errMsg,"Error")


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {/* {errMsg && showErrorMsg(msg, errMsg)} */}
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
          employee added successfully
          </Alert>
        </Stack>
      ) : null}
      {errMsg ? (
        // <h1 >xcvddssvdsfswfvbdwfbdwfbdwadbdwfbdswdbcfbdwdbcbfdadbcv bfddvbc bfdadv</h1>
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Employee Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Employee
      </h4>
      <div className="flex space-x-2 justify-end">
        <button type="button" className="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>
      <form>
        <div className="grid xl:grid-cols-3  m:gap-2">
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="firstname"
              onChange={handleChange}
              placeholder="Enter First Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              name="lastname"
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Distributor</label>
            <select
              className="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="distributor"
              name="distributor"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Distributor
              </option>
              {distributor.map((distributorList) =>  (  
              <option key={distributorList._id} value={distributorList._id}>{distributorList.firmName}</option>
              ))}
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Per Delivery Rate</label>
            <input
              type="number"
              className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="delivery"
              name="delivery"
              onChange={handleChange}
              placeholder="Enter Per Delivery Rate"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              onChange={handleChange}
              placeholder="Enter Email Address"
              required
            />
            <p class="invisible peer-invalid:visible text-red-700 font-light">
              Please Enter Your Valid Email Address
            </p>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Password</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="password"
              name="password"
              onChange={handleChange}
              placeholder=" Enter Password"
            />
            {password >= 6 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You Have 6 Digit Password
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Atleast 6 Digit Password
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile Number</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobile"
              name="mobile"
              onChange={handleChange}
              placeholder="Enter Mobile Number"
              required
              onInput={(e) => {
								e.target.value = e.target.value.toString().slice(0, 10);
							}}
            />
            {mobile == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You ave 10 digit mobile number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid Mobile Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gender"
              name="gender"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Gender
              </option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label> Pin Code</label>
            <Pincode
              getData={(data) => setPincodeData(data)}
              pincodeInput={{
                width: "100%",
                height: 38,
                border: "1px solid lightgrey",
                borderRadius: 5,
                padding: "10px",
              }}
              invalidError="Please check pincode"
              Container={{ display: "flex", flexDirection: "column-reverse" }}
              showArea={false}
              showCity={false}
              showState={false}
              showDistrict={false}
              placeholder="Enter Your Pincode"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              onChange={handleChange}
              placeholder="Enter Full Address"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              value={pincodeData.city}
              placeholder="Enter Your City"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>District</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="district"
              name="district"
              value={pincodeData.district}
              placeholder="Enter Your District"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="stateName"
              value={pincodeData.stateName}
              placeholder="Enter Your State"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"
              value={country}
              placeholder="Enter Your Country"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Adhar Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="adharcard"
              name="addharcard"
              onChange={handleChange}
              placeholder="Enter Adhar Card Number"
              required
              maxLength={12}
            />
            {adharcard == 12 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You Have 12 Digit Aadhar Card Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid 12 Digit Aadhar Card Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pancard"
              name="panCard"
              onChange={handleChange}
              placeholder="Enter Pan Card Number"
              required
              maxLength={10}
            />
            {pancard == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You Have 10 Digit Valid Pan Card Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid 10 Digit Pan Card Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankname"
              name="bankdetails"
              onChange={handleChange}
              placeholder="Enter Bank Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchname"
              name="branchname"
              onChange={handleChange}
              placeholder="Enter Branch Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="acctype"
              name="accountype"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Account Type
              </option>
              <option value="SAVING">Saving Account</option>
              <option value="CURRENT">Current Account</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account Number</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accnumber"
              name="accountno"
              onChange={handleChange}
              placeholder="Enter Account Number"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>UPI No</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="upi"
              value={addData.upi}
              onChange={handleChange}
              placeholder="Enter UPI No"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifsccode"
              name="ifscode"
              onChange={handleChange}
              placeholder="Enter IFSC Code"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="employee"
              name="employee"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Employee Type
              </option>
              <option value="SELF">Self</option>
              <option value="OTHER">Individual</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Status</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="status"
              name="status"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Employee Status
              </option>
              <option value="FREE">Free</option>
              <option value="BUSY">Busy</option>
            </select>
          </div>

          <div>
            <label style={{ paddingLeft: "12px" }}>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    // accept="image/jpeg, image/png"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button
          style={{ width: "8%" }}
          type="submit"
          onClick={handleSubmit}
          class="addButton"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddEmployee;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { ImCross } from 'react-icons/im';
// import '../view.css';
// import { MobileStepper } from '@mui/material';
// import axios from 'axios';
// import { useContext } from 'react';
// import StateContext from '../../contexts/ContextProvider';

// const AddEmployee = () => {
// 	const navigate = useNavigate();

// 	const mainPage = () => {
// 		navigate('/employees');
// 	};

// 	//ImagePreview
// 	const [ image, setImage ] = useState([]);
// 	const [ fName, setFName ] = useState('');
// 	const [ lName, setLName ] = useState('');
// 	const [ mobile, setMobile ] = useState('8974561230');
// 	const [ email, setEmail ] = useState('madhutest@gmil.com');
// 	const [ pdr, setPdr ] = useState('');
// 	const [ aadhaar, setAadhaar ] = useState('');
// 	const [ pan, setPan ] = useState('');
// 	const [ pass, setPass ] = useState('123456');
// 	const [ active, setActive ] = useState(true);
// 	const [ gender, setGender ] = useState('MALE');
// 	function uploadSingleImage(e) {
// 		setImage([ ...image, URL.createObjectURL(e.target.files[0]) ]);
// 		console.log('Image', image);
// 	}

// 	// function upload(e) {
// 	//   e.preventDefault();
// 	//   console.log(image);
// 	// }

// 	function deleteImage(e) {
// 		const s = image.filter((item, index) => index != e);
// 		setImage(s);
// 		console.log(s);
// 	}
// 	const handleAdd = async (e) => {
// 		e.preventDefault();

// 		const newEmployee = {
// 			firstName: fName,
// 			lastName: lName,
// 			mobile: mobile,
// 			password: pass,
// 			role: 'EMPLOYEEE',
// 			email: email,
// 			gender: gender,
// 			deliveryPerRate: pdr,
// 			panCard: pan,
// 			addharCard: aadhaar,
// 			ActiveInactive: active
// 		};
// 		const config = { headers: { 'Content-Type': 'application/json', 'x-token': sessionStorage.getItem('token') } };
// 		await axios
// 			.post('https://api.orbitmart.co.in/api/v1/auth/employee/merchant/register', newEmployee, config)
// 			.then((res) => console.log(res.data));
// 	};
// 	//

// 	//

// 	// setTimeout(() => {
// 	//   mainPage();
// 	// }, 1000);

// 	return (
// 		<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
// 			<h4 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">Employee Add</h4>
// 			<div className="flex space-x-2 justify-end">
// 				<button type="button" className="backButton" style={{ width: '5%' }} onClick={() => mainPage()}>
// 					Back
// 				</button>
// 			</div>

// 			<form onSubmit={handleAdd}>
// 				<div className="grid xl:grid-cols-3  m:gap-2">
// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="firstname" className="form-label inline-block mb-2 text-gray-700">
// 							First Name
// 						</label>
// 						<input
// 							type="text"
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="firstname"
// 							placeholder="Enter First Name"
// 							value={fName}
// 							onChange={(e) => setFName(e.target.value)}
// 						/>
// 					</div>
// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="lastname" className="form-label inline-block mb-2 text-gray-700">
// 							Last Name
// 						</label>
// 						<input
// 							type="text"
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="lastname"
// 							placeholder="Enter Last Name"
// 							value={lName}
// 							onChange={(e) => setLName(e.target.value)}
// 						/>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="gender" className="form-label inline-block mb-2 text-gray-700">
// 							Gender
// 						</label>
// 						<div className="form-check form-check-inline">
// 							<input
// 								className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
// 								type="radio"
// 								name="inlineRadioOptions"
// 								id="inlineRadio1"
// 								value="MALE"
// 								onClick={(e) => setGender(e.target.value)}
// 								checked={gender === 'MALE' ? true : false}
// 							/>
// 							<label className="form-check-label inline-block text-gray-800" for="inlineRadio10">
// 								Male
// 							</label>
// 						</div>
// 						<div className="form-check form-check-inline">
// 							<input
// 								className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  mr-2 cursor-pointer"
// 								type="radio"
// 								name="inlineRadioOptions"
// 								id="inlineRadio2"
// 								value="FEMALE"
// 								onClick={(e) => setGender(e.target.value)}
// 								checked={gender === 'FEMALE' ? true : false}
// 							/>
// 							<label className="form-check-label inline-block text-gray-800" for="inlineRadio20">
// 								Female
// 							</label>
// 						</div>
// 					</div>
// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="deliveryrate" className="form-label inline-block mb-2 text-gray-700">
// 							Per Delivery Rate
// 						</label>
// 						<input
// 							type="number"
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="deliveryrate"
// 							placeholder="Enter Per Delivery Rate"
// 							value={pdr}
// 							onChange={(e) => setPdr(e.target.value)}
// 						/>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="mobileno" className="form-label inline-block mb-2 text-gray-700">
// 							Mobile No:
// 						</label>
// 						<input
// 							type="number"
// 							required
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="mobileno"
// 							placeholder="Enter Mobile No"
// 							value={mobile}
// 							onChange={(e) => setMobile(e.target.value)}
// 						/>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="email" className="form-label inline-block mb-2 text-gray-700">
// 							Email Address
// 						</label>
// 						<input
// 							type="email"
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="email"
// 							placeholder="Enter Email Id"
// 							value={email}
// 							onChange={(e) => setEmail(e.target.value)}
// 							autocomplete="new-password"
// 							required
// 						/>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="addhar" className="form-label inline-block mb-2 text-gray-700">
// 							Aadhaar Card
// 						</label>
// 						<input
// 							type="number"
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="aadhaar"
// 							placeholder="Enter Aadhaar Card Number"
// 							value={aadhaar}
// 							onChange={(e) => setAadhaar(e.target.value)}
// 						/>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="pancard" className="form-label inline-block mb-2 text-gray-700">
// 							Pan Card
// 						</label>
// 						<input
// 							type="number"
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="pancard"
// 							placeholder="Enter Pan Card Number"
// 							value={pan}
// 							onChange={(e) => setPan(e.target.value)}
// 						/>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="password" className="form-label inline-block mb-2 text-gray-700">
// 							Password
// 						</label>
// 						<input
// 							type="password"
// 							className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
// 							id="password"
// 							placeholder="Enter Password"
// 							value={pass}
// 							onChange={(e) => setPass(e.target.value)}
// 							autocomplete="new-password"
// 							required
// 						/>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						<label for="Status" className="form-label inline-block mb-2 text-gray-700" />
// 						<input
// 							className="form-check-input h-4 w-4 border border-gray-300 rounded-sm checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-contain float-left mr-2 cursor-pointer"
// 							// checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-contain float-left mr-2 cursor-pointer"
// 							type="checkbox"
// 							checked={active}
// 							onClick={(e) => setActive(!active)}
// 							id="flexCheckDefault"
// 						/>
// 						<label className="form-check-label inline-block text-gray-800" for="flexCheckDefault">
// 							Active / Inactive
// 						</label>
// 					</div>

// 					<div className="relative z-0 p-2 w-full mb-6 group">
// 						{image.length > 0 &&
// 							image.map((item, index) => {
// 								return (
// 									<div style={{ display: 'inline-block' }} key={item}>
// 										<p className="block-icon">
// 											<img src={item} alt="img" />
// 											<ImCross
// 												style={{ cursor: 'pointer' }}
// 												onClick={() => deleteImage(index)}
// 												className="icon-tag"
// 											/>
// 										</p>

// 										{/* <button type="button" onClick={() => deleteImage(index)}>
//                       Delete
//                     </button> */}
// 									</div>
// 								);
// 							})}
// 						{/* <input
//               accept="image/*"
//               disabled={image.length === 1}
//               type="file"
//               onChange={uploadSingleImage}
//               className="form-control
//         block
//         w-full
//         px-3
//         py-1.5
//         text-base
//         font-normal
//         text-gray-700
//         bg-white bg-clip-padding
//         border border-solid border-gray-300
//         rounded
//         transition
//         ease-in-out
//         m-0
//         focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
//               id="image"
//             /> */}
// 					</div>
// 				</div>
// 				<div className="flex space-x-2 justify-end">
// 					<button type="submit" className="addButton" style={{ width: '5%' }}>
// 						Add
// 					</button>
// 				</div>
// 			</form>
// 		</div>
// 	);
// };

// export default AddEmployee;
