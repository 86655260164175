import { SelectedDataIndexDirective } from "@syncfusion/ej2-react-charts";
import axios from "axios";
import React from "react";
import { useEffect,useContext,useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";


const EditOrder = () => {
  const navigate = useNavigate();
  // const {id}=useParams();
  // console.log(id)
   const {setOrderData,orderData,setOrders}=useContext(StateContext)
   const [os,setOS]=useState(orderData.orderStatus)
   const [ps,setPS]=useState(orderData.paymentStatus)
  // useEffect(async()=>{
  // await axios.get(`https://api.orbitmart.co.in/api/v1/order/${id}`).then((res)=>setOrderData(res.data.data))
  // console.log(orderData)
  // },[])

  console.log(orderData)
  const mainPage = () => {
    navigate("/orders");
  };
  const updateOrder=async()=>{
   
    const updatedOrderInfo={...orderData, paymentStatus:ps,orderStatus:os}
//  console.log(updatedOrderInfo,,)

const config = { headers: { "Content-Type": "application/json","x-token":sessionStorage.getItem('token') } };
    
const updated=await axios.put(`https://api.orbitmart.co.in/api/v1/order/${orderData._id}`,updatedOrderInfo,config).then((res)=> res.data)
if(updated.status=="SUCCESS")
{
  
 navigate("/orders");
}

  }
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
         Order No:{orderData.orderNo}
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button type="button" className="editButton" style={{ width: "8%" }}  onClick={() => updateOrder()}>
          Edit
        </button>
      </div>

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Order No
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="unit"
              value={orderData.orderNo}
            />
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Client Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="unit"
              value={orderData?.userId?.firstName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Order Date & Time
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="unit"
           value={new Date(orderData.createdAt).toLocaleString("en-US", {
            weekday: "short",
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Order Amount
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="unit"
              value={orderData.grandTotal}
            />
          </div>
        
        
 
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Payment Mode
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="unit"
              value={orderData.paymentMode}
            />
          </div>

     
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Payment Status
            </label>

  <div class="btn-group d-block">
        <button type="button" className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out btn dropdown-toggle m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none d" id="ps" data-bs-toggle="dropdown">{ps}</button>
        <div className="dropdown-menu w-100" >
            <a  className="dropdown-item" onClick={(e)=>setPS(e.currentTarget.textContent)} >PENDING</a>
            <a  className="dropdown-item" onClick={(e)=>setPS(e.currentTarget.textContent)}>COMPLETED</a>
      
        </div>
    </div>
    
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="unit"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Order Status
            </label>

  <div class="btn-group d-block">
        <button type="button" className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out btn dropdown-toggle m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none d" data-bs-toggle="dropdown">{os}</button>
        <div class="dropdown-menu w-100">
            <a className="dropdown-item" onClick={(e)=>setOS(e.currentTarget.textContent)}>PENDING</a>
            <a  className="dropdown-item" onClick={(e)=>setOS(e.currentTarget.textContent)}>PROCESSING</a>
            <a  className="dropdown-item" onClick={(e)=>setOS(e.currentTarget.textContent)}>PACKAGING</a>
            <a className="dropdown-item" onClick={(e)=>setOS(e.currentTarget.textContent)}>SHIPPING</a>
            <a  className="dropdown-item" onClick={(e)=>setOS(e.currentTarget.textContent)}>REJECTED</a>
        </div>
    </div>
    
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditOrder;
