import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

// import { invoiceData, contextMenuItems, invoiceGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";

export const invoiceGrid = [
  {
    field: "id",
    headerText: "Id",
    width: "30",
    editType: "dropdownedit",
    textAlign: "Center",
  },
  {
    field: "invoiceno",
    headerText: "Invoice No",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "invoiceamount",
    headerText: "Invoice Amount",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "mobileno",
    headerText: "Mobile No",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "email",
    headerText: "E Mail",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "invoicedate",
    headerText: "Invoice Date",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "paymentstatus",
    headerText: "Payment Status",
    width: "20",
    textAlign: "Center",
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const invoiceData = [
  {
    id: 1,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 2,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 3,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 4,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 5,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 6,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 7,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 8,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 9,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
  {
    id: 10,
    invoiceno: "GT/25",
    invoiceamount: 2000,
    mobileno: 9898989898,
    email: "admin@gmail.com",
    invoicedate: "03-03-2022",
    paymentstatus: "Done",
  },
];

const Invoice = () => {
  const editing = { allowDeleting: true, allowEditing: true };

  const navigate = useNavigate();
  const addPage = () => {
    navigate("/invoice/add");
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Invoices" />
      <button
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={() => addPage()}
      >
        Send Invoice
      </button>
      <GridComponent
        id="gridcomp"
        dataSource={invoiceData}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {invoiceGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Sort,
            ContextMenu,
            Filter,
            Page,
            ExcelExport,
            Edit,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default Invoice;
