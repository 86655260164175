import React, { useEffect, useState, useContext } from 'react';

import { Alert, Stack } from '@mui/material';
import { Header } from '../../components';
import { useNavigate } from 'react-router-dom';
// import axios from '../api/axiosapi';
import { data } from 'autoprefixer';
import { BsFillPencilFill } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import NewToggle from '../../pages/newToggle/NewToggle';
import { Link } from 'react-router-dom';
import ViewDistributor from './ViewDistributor';
import EditDistributors from './EditDistributors';
import StateContext from '../../contexts/ContextProvider';
import DeleteDistributorModal from './DeleteDistributorModal';
import DistributorToggle from './DistributorToggle';
import axios from 'axios';
import {
	FirstPageOutlined,
	LastPageOutlined,
	NavigateBefore,
	NavigateNext,
  } from "@material-ui/icons";
import {
  AppBar,
  Divider,
  Fade,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";
const useStyles = makeStyles((theme) => ({
	table: {
	  minWidth: 650,
	  borderCollapse: "collapse",
	},
	cell: {
	  padding: "8px 0 8px 16px",
	  textAlign: "start",
	  // border: "1px solid " + theme.palette.divider,
	},
	cellRow: {
	  // border: "1px solid " + theme.palette.divider,
	  padding: "15px",
	  textAlign: "start",
	},
  }));

//GridPart

const Distributors = () => {
	const [ tDatas, setTDatas ] = useState([]);
	const { distributor,setDistributor, setUserId, userInfo, setUserInfo, setOpen } =
    useContext(StateContext);

	const [ fetched, setFetched ] = useState(false);

	const { setData } = useContext(StateContext);
	const { setViewData } = useContext(StateContext);

	const { value } = useContext(StateContext);
	const { setValue } = useContext(StateContext);

	const { statusData } = useContext(StateContext);

	const { alertData } = useContext(StateContext);
	const [searchQuery, setSearchQuery] = useState("");
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10);
  const [length, setLength] = useState(0)
  const getToken = sessionStorage.getItem("token")
	// const random = 223;


	// useEffect(() => {
	//   fetchAllDistributor();
	// }, []);

	// async function fetchAllDistributor() {
	//   const req = await axios.get(
	//     "https://api.orbitmart.co.in/api/v1/auth/allDistributor"
	//   );
	//   const data = req.data.data;
	//   console.log(data);
	// }

	const random = 518;

	const fetchData = async () => {
		const header = {
          "x-token": getToken
		}
		try {
			// const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/allDistributor');
			const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/admin/allDistributor', {
				headers: header
			});
			setDistributor(response.data.data);
			setLength(response.data.data.length)
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};


	var itemId = distributor;

	// const gridSrno = (itemId) => {
	//   let sr = itemId.index;
	//   sr++;
	//   return sr;
	// };
	// debugger;
	// if (itemId != undefined) {
	//   debugger;
	//   var totalData = itemId.length;
	//   var srIndex = itemId;
	//   console.log(totalData);
	//   console.log(srIndex);
	// }

	// console.log(totalData);
	// console.log(itemId);

	const gridSrno = (itemId) => {
		var sr = itemId.index;
		sr++;
		return sr;
	};

	function gridActions(itemId) {
		return (
			<div className="flex items-center gap-2">
				<h1
					style={{ color: '#8be78b', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<Link style={{ marginRight: 'auto' }} to={`/distributors/edit/${itemId._id}`}>
						<BsFillPencilFill onClick={() => itemEdit(itemId)} />
					</Link>
				</h1>
				<h1
					style={{ color: '#03c9d7', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<Link style={{ marginLeft: 'auto', marginRight: 'auto' }} to={`/distributors/view/${itemId._id}`}>
						<FiEye onClick={() => itemView(itemId)} />
					</Link>
				</h1>
				<button
					style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<DeleteDistributorModal itemId={itemId._id} />
				</button>
			</div>
		);
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (value == 200) {
		// fetchData();
		setValue(random);
	}

	// const itemDelete = (itemId) => {
	//   const deleteId = itemId._id;
	//   console.log(deleteId);
	//   fetch(`https://api.orbitmart.co.in/api/v1/auth/distributor/${deleteId}`, {
	//     method: "DELETE",
	//   })
	//     .then((res) => res.text()) // or res.json()
	//     .then((res) => console.log(res));
	// };

	const itemView = (itemId) => {
		const viewId = itemId._id;
		console.log(viewId);
		setViewData(itemId);
	};

	const itemEdit = (itemId) => {
		const editId = itemId._id;
		console.log(editId);
		setData(itemId);
	};

	function gridStatus(distributor) {
		let adminActiveInactive = distributor.adminActiveInactive;
		return (
			<div class="flex items-center">
				<DistributorToggle tDatas={distributor} adminActiveInactive={adminActiveInactive} />
			</div>
		);
	}

	const selectionsettings = { persistSelection: true };
	const toolbarOptions = [ 'Search' ];

	const editing = { allowDeleting: true };

	const navigate = useNavigate();

	const addPage = () => {
		navigate('/distributors/add');
	};

	// const tableDatas = tDatas.map(function (values) {
	//   return values;
	// });

	function alertShow() {
		return alertData ? (
			<Stack
				sx={{ width: '30%' }}
				style={{
					position: 'top',
					align: 'center',
					left: '22%',
					bottom: '1%',
					width: '30%'
				}}
				spacing={2}>
				<Alert variant="filled" severity="success">
					{statusData}
				</Alert>
			</Stack>
		) : null;
	}

	
	const handleChangePage = (event, newPage) => {
		setCurrentPage(newPage);
	  };
	  const handleChangeRowsPerPage = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	  };
	  const changeToFirstPage = () => {
		setLoading(true);
		setCurrentPage(0);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToPrevPage = () => {
		setLoading(true);
		setCurrentPage(currentPage - 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToNextPage = () => {
		setLoading(true);
		setCurrentPage(currentPage + 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToLastPage = () => {
		setLoading(true);
		setCurrentPage(Math.ceil(distributor.length / pageSize) - 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };

	  const filteredData = distributor?.filter(item => 
		item?.firmName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item?.distributorId?.firmName.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item?.mobile?.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item?.pincode?.toLowerCase().includes(searchQuery.toLowerCase())
	)

	const paginatedData = filteredData.slice(
		currentPage * pageSize,
		currentPage * pageSize + pageSize
	  );
		console.log(distributor, "Qwf", filteredData, "page", paginatedData)

	return (
		<div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
		  <div className="flex space-x-2 m-4 justify-end">
			<button type="button" onClick={() => addPage()} className="addBtn">
			  New Record
			</button>
		  </div>
		  <AppBar
			position="static"
			style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
		  >
			<Toolbar style={{ justifyContent: "space-between" }}>
			  <Typography variant="h6">Distributors ({length})</Typography>
			  <span
				className="flex space-x-2 justify-end"
				style={{ background: "white", borderRadius: "6px" }}
			  >
				<input
				  style={{
					padding: "4px 10px",
					outline: "none",
					borderRadius: "6px 0 0 6px",
					color: "black",
				  }}
				  type="text"
				  placeholder="Search..."
				  value={searchQuery}
				  onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<BiSearch
				  style={{
					fontSize: "25px",
					color: "gray",
					cursor: "pointer",
					margin: "auto",
					paddingRight: "5px",
				  }}
				/>{" "}
				{/*  this is the search icon */}
			  </span>
			</Toolbar>
		  </AppBar>
		  {/* {console.log({ filteredData })} */}
		  <div style={{ marginTop: "10px" }}></div>
		  <Divider />
		  <TableContainer>
			<Table>
			  <TableHead>
				<TableRow>
				  <TableCell className={classes.cellRow}>Firm Name</TableCell>
				  <TableCell className={classes.cellRow}>Email</TableCell>
				  <TableCell className={classes.cellRow}>Mobile</TableCell>
				  <TableCell className={classes.cellRow}>Gender</TableCell>
				  <TableCell className={classes.cellRow}>Pin Code</TableCell>
				  <TableCell className={classes.cellRow}>Status</TableCell>
				  {/* {headerValue == "" ? null : <TableCell className={classes.cellRow}>{headerValue}</TableCell>} */}
				  <TableCell className={classes.cellRow} style={{textAlign: 'start'}}>Action</TableCell>
				</TableRow>
			  </TableHead>
			  <TableBody>
				{paginatedData.length > 0 ? (
				  paginatedData?.map((item) => (
					<TableRow key={item._id}>
					  <TableCell
						className={classes.cell}>{item?.firmName}</TableCell>
					  <TableCell className={classes.cell}>{item?.email}</TableCell>
					  <TableCell className={classes.cell}>{item?.mobile}</TableCell>
					  <TableCell className={classes.cell}>{item?.gender}</TableCell>
					  <TableCell className={classes.cell}>{item?.pincode}</TableCell>
					  <TableCell className={classes.cell}>
						{gridStatus(item)}{" "}
					  </TableCell>
					  <TableCell className={classes.cell}>
						{gridActions(item)}
					  </TableCell>
					</TableRow>
				  ))
				) : (
				  <TableRow>
					<TableCell colSpan={7} align="center">
					  No Data Available
					</TableCell>
				  </TableRow>
				)}
			  </TableBody>
			</Table>
			{length > 0 ? (
			  <TablePagination
				count={length}
				component="div"
				page={currentPage}
				rowsPerPage={pageSize}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[10]}
				ActionsComponent={() => (
				  <div style={{ display: "flex", alignItems: "center" }}>
					<Button
					  onClick={changeToFirstPage}
					  disabled={currentPage === 0}
					>
					  <FirstPageOutlined />
					</Button>
					<Button onClick={changeToPrevPage} disabled={currentPage === 0}>
					  <NavigateBefore />
					</Button>
					<span className="myPage">{currentPage + 1}</span>
					<Button
					  onClick={changeToNextPage}
					  disabled={
						currentPage >= Math.ceil(length / pageSize) - 1
					  }
					>
					  <NavigateNext />
					</Button>
					<Button
					  onClick={changeToLastPage}
					  disabled={
						currentPage >= Math.ceil(length / pageSize) - 1
					  }
					>
					  <LastPageOutlined />
					</Button>
				  </div>
				)}
			  />
			) : null}
		  </TableContainer>
		</div>	
	
	  );

	// return (
	// 	<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
	// 		<Header title="Distributors" />
	// 		{/* <Header category="Page" title="Distributors" /> */}
	// 		<div class="flex space-x-2 m-4 justify-end">
	// 			<button type="button" onClick={() => addPage()} class="addButton">
	// 				New Record
	// 			</button>
	// 		</div>
	// 		<GridComponent
	// 			id="gridcomp"
	// 			dataSource={tDatas.data}
	// 			width="auto"
	// 			allowPaging
	// 			allowSorting
	// 			allowExcelExport
	// 			allowPdfExport
	// 			pageSettings={{ pageCount: 5 }}
	// 			contextMenuItems={contextMenuItems}
	// 			selectionSettings={selectionsettings}
	// 			editSettings={editing}
	// 			toolbar={toolbarOptions}
	// 			allowFiltering={true}
	// 			// filterSettings={{ignoreAccent:true, type:'Menu'}}
	// 			// filterSettings={{ ignoreAccent: true, type: "CheckBox" }}
	// 			filterSettings={{ ignoreAccent: true, type: 'Excel' }}>
	// 			<ColumnsDirective>
	// 				<ColumnDirective headerText="Sr No" template={gridSrno} textAlign="center" />
	// 				{distributorsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
	// 				<ColumnDirective headerText="Status" template={gridStatus} textAlign="Center" />
	// 				<ColumnDirective headerText="Actions" template={gridActions} textAlign="Center" />
	// 			</ColumnsDirective>
	// 			<Inject
	// 				services={[
	// 					Resize,
	// 					Search,
	// 					ContextMenu,
	// 					Page,
	// 					Selection,
	// 					Toolbar,
	// 					Edit,
	// 					Sort,
	// 					Filter,
	// 					ExcelExport,
	// 					PdfExport
	// 				]}
	// 			/>
	// 		</GridComponent>
	// 	</div>
	// );
};
export default Distributors;
