import React from 'react'
import {
    TextField,
    Button,
    Typography,
    makeStyles,
    Paper,
    Grid,
    IconButton,
    Avatar,
    Input,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    AppBar,
    Toolbar,
    CircularProgress,
  } from "@material-ui/core";
  import './viewUser.css'

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    avatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    editIcon: {
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "50%",
    },
    button: {
      marginRight: theme.spacing(2),
    },
    statusContainer: {
      display: "flex",
      alignItems: "center",
    },
  }));

const UserDetails = ({userInfo}) => {
    const classes = useStyles();
    console.log(userInfo, "userInfo")

    const dateStrLast = userInfo.updatedAt;
const dateLast = new Date(dateStrLast);

const optionsLast = { day: '2-digit', month: 'short', year: 'numeric' };

const formattedDateLast = dateLast.toLocaleDateString('en-GB', optionsLast).replace(/ /g, ' ');
console.log();

    const dateStr = userInfo.createdAt;
const date = new Date(dateStr);

const options = { day: '2-digit', month: 'short', year: 'numeric' };

const formattedDate = date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
console.log(formattedDate);
  return (
    <>
    <div class="info-container">
  <h2>General Information</h2>
  <div class="info-grid">
    <div class="info-field">
      <label for="firstName">First Name</label>
      <div id="firstName" class="info-value">{userInfo.firstName || ""}</div>
    </div>
    <div class="info-field">
      <label for="lastName">Last Name</label>
      <div id="lastName" class="info-value">{userInfo.lastName || ""}</div>
    </div>
    <div class="info-field">
      <label for="gender">Gender</label>
      <div id="gender" class="info-value">{userInfo.gender || ""}</div>
    </div>
    <div class="info-field">
      <label for="mobile">Mobile</label>
      <div id="mobile" class="info-value">{userInfo.mobile || ""}</div>
    </div>
    <div class="info-field">
      <label for="email">Email</label>
      <div id="email" class="info-value">{userInfo.email || ""}</div>
    </div>
    <div class="info-field">
      <label for="status">Status</label>
      <div id="status" class="info-value">{userInfo.status || ""}</div>
    </div>
    <div class="info-field">
      <label for="memberSince">Member Since</label>
      <div id="memberSince" class="info-value">{formattedDate || ""}</div>
    </div>
    <div class="info-field">
      <label for="recentPurchases">Recent Purchases</label>
      <div id="recentPurchases" class="info-value">{formattedDateLast || ""}</div>
    </div>
    <div class="info-field">
      <label for="address">Address</label>
      <div id="address" class="info-value">{userInfo?.address || "."}</div>
    </div>
    <div class="info-field">
      <label for="pincode">Pincode</label>
      <div id="pincode" class="info-value">{userInfo.pincode || ""}</div>
    </div>
  </div>
</div>
    {/* <Paper className={classes.paper}>
            <Typography variant="h6" style={{color :"#66cdaa"}}>General Information</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={userInfo.firstName || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="gender"
                  name="gender"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.gender}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Mobile"
                  name="mobile"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.mobile}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.email}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Status"
                  name="status"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.status}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Member Since"
                  name="date"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={formattedDate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Recent Purchases"
                  name="Recent Purchases Date"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={formattedDateLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Address"
                  name="address"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.address}
                />
              </Grid>
              
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Pincode"
                  name="pincode"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.pincode}
                />
              </Grid>
              
            </Grid>
          </Paper> */}
    </>
  )
}

export default UserDetails