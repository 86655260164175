import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { BsFillPencilFill } from "react-icons/bs";
import DeleteModal from "../../deleteModal/DeleteModal";

const gridItemAction = () => (
  <div className="flex items-center justify-center gap-2">
    <h1
      style={{ color: "#03c9d7", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link
        style={{ marginLeft: "auto", marginRight: "auto" }}
        to="/users/view"
      >
        <FiEye />
      </Link>
    </h1>
    <h1
      style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
      onClick={(e) => deleteItem(e.id)}
    >
      <DeleteModal />
    </h1>
  </div>
);

const deleteItem = (id) => {
  console.log(id);
};

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const userGrid = [
  {
    field: "_id",
    headerText: "Id",
    textAlign: "Center",
  },
  {
    field: "domainType",
    headerText: "Name",
    textAlign: "Center",
  },
  //   {
  //     field: "lastname",
  //     headerText: "Last Name",
  //     textAlign: "Center",
  //   },
  //   {
  //     field: "mobileno",
  //     headerText: "Mobile No",
  //     textAlign: "Center",
  //   },
  //   {
  //     field: "email",
  //     headerText: "Email",
  //     textAlign: "Center",
  //   },
  //   {
  //     headerText: "Status",
  //     template: gridUsersStatus,
  //     field: "id",
  //     textAlign: "Center",
  //   },
  {
    field: "action",
    headerText: "Action",
    textAlign: "Center",
    template: gridItemAction,
  },
];

export const userData = [
  {
    id: 1,
    firstname: "Asha",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "active",
    // StatusBg: "#03C9D7",
  },
  {
    id: 2,
    firstname: "Asha",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "active",
    // StatusBg: "#03C9D7",
  },
  {
    id: 3,
    firstname: "Nick",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "active",
    // StatusBg: "#03C9D7",
  },
  {
    id: 4,
    firstname: "Asha",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "active",
    // StatusBg: "#03C9D7",
  },
  {
    id: 5,
    firstname: "Asha",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "active",
    // StatusBg: "#03C9D7",
  },
  {
    id: 6,
    firstname: "Rhul",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "inactive",
    // StatusBg: "#FB9678",
  },
  {
    id: 7,
    firstname: "Rony",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "inactive",
    // StatusBg: "#FB9678",
  },
  {
    id: 8,
    firstname: "Asha",
    // lastname: "Singa",
    // mobileno: 9898989898,
    // email: "a@gmail.com",
    // Status: "active",
    // StatusBg: "#03C9D7",
  },
  //   {
  //     id: 9,
  //     firstname: "Disha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 10,
  //     firstname: "Shreya",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "inactive",
  //     StatusBg: "#FB9678",
  //   },
  //   {
  //     id: 11,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 12,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 13,
  //     firstname: "Nick",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 14,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 15,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 16,
  //     firstname: "Rhul",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "inactive",
  //     StatusBg: "#FB9678",
  //   },
  //   {
  //     id: 17,
  //     firstname: "Rony",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "inactive",
  //     StatusBg: "#FB9678",
  //   },
  //   {
  //     id: 18,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 19,
  //     firstname: "Disha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 20,
  //     firstname: "Shreya",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "inactive",
  //     StatusBg: "#FB9678",
  //   },
  //   {
  //     id: 21,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 22,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 23,
  //     firstname: "Nick",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 24,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 25,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 26,
  //     firstname: "Rhul",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "inactive",
  //     StatusBg: "#FB9678",
  //   },
  //   {
  //     id: 27,
  //     firstname: "Rony",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "inactive",
  //     StatusBg: "#FB9678",
  //   },
  //   {
  //     id: 28,
  //     firstname: "Asha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 29,
  //     firstname: "Disha",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "active",
  //     StatusBg: "#03C9D7",
  //   },
  //   {
  //     id: 30,
  //     firstname: "Shreya",
  //     lastname: "Singa",
  //     mobileno: 9898989898,
  //     email: "a@gmail.com",
  //     Status: "inactive",
  //     StatusBg: "#FB9678",
  //   },
];
