import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

// import { itemsData, contextMenuItems, itemsGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { BsFillPencilFill } from "react-icons/bs";
import { AiOutlinePlusSquare } from "react-icons/ai";

const gridItemEdit = () => (
  <div className="flex items-center justify-center gap-2">
    <span>
      <Link to="/manageItem/edit">
        <BsFillPencilFill />
      </Link>
    </span>
  </div>
);

export const itemsGrid = [
  {
    field: "id",
    headerText: "Id",
    width: "20",
    editType: "dropdownedit",
    textAlign: "Center",
  },
  {
    field: "merchanttype",
    headerText: "Merchant Type",
    width: "50",
    textAlign: "Center",
  },
  {
    field: "classification",
    headerText: "Classification",
    width: "60",
    textAlign: "Center",
  },
  {
    field: "itemname",
    headerText: "Item Name",
    width: "50",
    textAlign: "Center",
  },
  {
    field: "quantity",
    headerText: "Quantity",
    format: "C2",
    textAlign: "Center",
    editType: "numericedit",
    width: "60",
  },
  {
    field: "description",
    headerText: "Description",
    width: "70",
    textAlign: "Center",
  },
  {
    field: "merchantstatus",
    headerText: "Merchant Status",
    textAlign: "Center",
    width: "40",
  },
  {
    field: "distributorstatus",
    headerText: "Distributor Status",
    textAlign: "Center",
    width: "40",
  },
  {
    field: "adminstatus",
    headerText: "Admin Status",
    textAlign: "Center",
    width: "40",
  },
  {
    field: "action",
    headerText: "Action",
    width: "35",
    textAlign: "Center",
    template: gridItemEdit,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const itemsData = [
  {
    id: 1,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 2,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 3,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 4,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 5,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 6,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 7,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 8,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 9,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
  {
    id: 10,
    merchanttype: "Restraunt",
    classification: "Pizza",
    itemname: "The Pasta Place",
    quantity: "100",
    description: "Continetal Food",
    merchantstatus: "Active",
    distributorstatus: "Active",
    adminstatus: "Active",
  },
];

const ManageItem = () => {
  const editing = { allowDeleting: true, allowEditing: true };

  const navigate = useNavigate();

  const addPage = () => {
    navigate("/manageItem/add");
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Manage Items" />
      <button
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={() => addPage()}
      >
        New Record
      </button>

      <GridComponent
        id="gridcomp"
        dataSource={itemsData}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {itemsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Sort,
            ContextMenu,
            Filter,
            Page,
            ExcelExport,
            Edit,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default ManageItem;
