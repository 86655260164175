import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Pincode from "react-pincode";

const EditMerchant = () => {
  const { id } = useParams();
  console.log(id);

  const { viewData } = useContext(StateContext);
  console.log(viewData._id);

  const [editData, setEditData] = useState(viewData);

  const [tDatas, setTDatas] = useState([]);
  const [types, setTypes] = useState([]);

  const [distributor, setDistributor] = useState("");
  const [domainType, setDomainType] = useState("");
  const [classification, setClassification] = useState("");
  const [gender, setGender] = useState("");
  const [accountType, setAccountType] = useState("");
  const [pincodeData, setPincodeData] = useState(editData.pincode);

  console.log("Pin :", pincodeData);

  const fetchType = () => {
    fetch("https://api.orbitmart.co.in/api/v1/merchant/Type")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTypes(data.data);
      });
  };

  const fetchData = () => {
    fetch("https://api.orbitmart.co.in/api/v1/auth/allDistributor")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTDatas(data.data);
      });
  };

  useEffect(() => {
    fetchData();
    fetchType();
  }, [pincodeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/merchants");
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  console.log(id, "editId")
  async function handleSubmit(e) {
    const editId = viewData._id;
    e.preventDefault();
    if(editData.branchName.length === 0||editData.ifscCode.length === 0||editData.accountNo.length === 0||editData.email.length === 0||editData.mobile.length === 0||editData.panCard.length === 0||editData.addharCard.length === 0|| editData.country.length === 0 || editData.bankName.length === 0 || editData.firstName.length === 0 || editData.lastName.length === 0 || editData.firmName.length === 0 || editData.address.length === 0){
      setError(true)

      setTimeout(() => {
             setError(false)
            }, 3000);
      throw new Error('Please enter all fields')
    }
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = `https://api.orbitmart.co.in/api/v1/auth/distributor/${viewData._id}`;
    const formData = new FormData();
    formData.append("Image", selectedImages[0]);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          classification: editData.classification,
          distributorId: editData.distributorId,
          domainType: editData.domainType,
          firstName: editData.firstName,
          lastName: editData.lastName,
          firmName: editData.firmName,
          address: editData.address,
          state: pincodeData.stateName,
          city: pincodeData.city,
          district: pincodeData.district,
          pincode: pincodeData.pincode,
          country: editData.country,
          addharCard: editData.addharCard,
          panCard: editData.panCard,
          mobile: editData.mobile,
          email: editData.email,
          bankName: editData.bankName,
          bankAccountType: editData.bankAccountType,
          accountNo: editData.accountNo,
          ifscCode: editData.ifscCode,
          branchName: editData.branchName,
          avatar: coverPhoto,
        };
        console.log(finalData);
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then(async (response) => {
            debugger;
            const isJson = response.headers
              .get("content-type")
              ?.includes("application/json");
            const data = isJson && (await response.json());
            console.log(data);
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          })
          .catch((err) => {
            setError(true);
            console.log(err);
            setTimeout(() => {
              mainPage();
            }, 1000);
          });
      })
      .catch((err) => {
        setError(true);
        console.log(err);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
  }

  return (
    <div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Updated Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{
            position: "fixed",
            left: "22%",
            bottom: "1%",
            width: "30%",
          }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Edit Merchant
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
      </div>

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 2 }}
          >
            <label>Classification</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="classification"
              value={editData.classification}
              onChange={handleChange}
            >
              <option>-- Select Type --</option>
              <option value="WHOLESALER">Wholesaler</option>
              <option value="RETAILER">Retailer</option>
              <option value="MANUFACTURER">Manufacturer</option>
            </select>
          </div>

          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 2 }}
          >
            <label>Domain Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="domainType"
              value={editData.domainType}
              onChange={handleChange}
            >
              <option>-- Select Type --</option>
              {types.map((typeget) => (
                <option value={typeget.domainType}>{typeget.domainType}</option>
              ))}
            </select>
          </div>

          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 2 }}
          >
            <label>Distributors</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="distributorId"
              value={editData.distributorId}
              onChange={handleChange}
            >
              <option>-- Select Type --</option>
              {tDatas.map((typeget) => (
                <option value={typeget._id}>
                  {typeget.firstName} {""}
                  {typeget.lastName} {"--"} {""}
                  {typeget.pincode}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="firstName"
              value={editData.firstName}
              onChange={handleChange}
            />
            {/* {editData?.firstName.length === 0 && <p className="text-red-500">Please enter first name</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="lastName"
              value={editData.lastName}
              onChange={handleChange}
            />
            {/* {editData.lastName.length === 0 && <p className="text-red-500">Please enter last name</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Firm Name</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="firmName"
              value={editData.firmName}
              onChange={handleChange}
            />
            {/* {editData.firmName.length === 0 && <p className="text-red-500">Please enter firm name</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pin Code</label>
            <Pincode
              getData={(data) => setPincodeData(data)}
              pincodeInput={{
                width: "100%",
                height: 35,
                border: "2px solid grey",
                borderRadius: 5,
                paddingLeft: '12px'
              }}
              showArea={false}
              showCity={false}
              showState={false}
              showDistrict={false}

            />
            {/* {pincodeData.toString().length === 0 && <p className="text-red-500">Please enter first name</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="address"
              value={editData.address}
              onChange={handleChange}
            />
            {/* {editData.address.length === 0 && <p className="text-red-500">Please enter address</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              value={editData.city}
              placeholder="Enter Your City"
              onChange={handleChange}
              // disabled
            />
            {/* {editData.city.length === 0 && <p className="text-red-500">Please enter city</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>District</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="district"
              name="district"
              value={editData.district}
              placeholder="Enter Your District"
              // disabled
              onChange={handleChange}
            />
            {/* {editData.district.length === 0 && <p className="text-red-500">Please enter district</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="stateName"
              value={editData.state}
              placeholder="Enter Your State"
              onChange={handleChange}
              // disabled
              
            />
             {/* {editData.state.length === 0 && <p className="text-red-500">Please enter district</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Aadhar Card</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="addharCard"
              value={editData.addharCard}
              onChange={handleChange}
            />
            {/* {editData.addharCard.length === 0 && <p className="text-red-500">Please aadhaar card number</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="pancard"
              value={editData.panCard}
              onChange={handleChange}
            />
{/* {editData.panCard.length === 0 && <p className="text-red-500">Please pan card number</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile No</label>
            <input
              type="number"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="mobile"
              value={editData.mobile}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 12);
              }}
              disabled
            />
            {/* {editData.mobile.length === 0 && <p className="text-red-500">Please enter mobile number</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Email</label>
            <input
        type='email'
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="email"
              value={editData.email}
              onChange={handleChange}
              
            />
            {/* {editData.email.length === 0 && <p className="text-red-500">Please enter email</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="bankName"
              value={editData.bankName}
              onChange={handleChange}
            />
            {/* {editData.bankName.length === 0 && <p className="text-red-500">Please enter bank name</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="bankAccountType"
              value={editData.bankAccountType}
              onChange={handleChange}
            >
              <option>-- Select Type --</option>
              <option value="SAVING">SAVING</option>
              <option value="CURRENT">CURRENT</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account No</label>
            <input
              type="number"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="accountNo"
              value={editData.accountNo}
              onChange={handleChange}
              onWheel={(e) => e.target.blur()}
            />
            {/* {editData.accountNo.length === 0 && <p className="text-red-500">Please enter account number</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="ifscCode"
              value={editData.ifscCode}
              onChange={handleChange}
            />
            {/* {editData.ifscCode.length === 0 && <p className="text-red-500">Please enter ifsc code</p>} */}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="branchName"
              value={editData.branchName}
              onChange={handleChange}
            />
            {/* {editData.branchName.length === 0 && <p className="text-red-500">Please enter branch name</p>} */}
          </div>

          <div>
            <label class='ml-[10px]'>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={editData.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          onClick={handleSubmit}
          class="addButton"
          style={{ width: "8%" }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditMerchant;
