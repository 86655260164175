import React, { useState } from 'react';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyle from './styles';
import Input from './Input';
import { Link } from 'react-router-dom';
import './style.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import logo from './orbitmart-logo.png';
import { useNavigate } from 'react-router-dom';
import AddAdmin from '../addAdmin/AddAdmin';

const initialState = {
	email: '',
	password: ''
};

const panels = [
	{
		value: 'admin',
		label: 'Admin'
	},
	{
		value: 'distributor',
		label: 'Distributor'
	},
	{
		value: 'merchant',
		label: 'Merchant'
	}
];

const Auth = () => {
	const classes = useStyle();
	const [ formData, setFormData ] = useState(initialState);
	const [ panel, setPanel ] = useState('admin');

	const handleChange = (e) => {
		setPanel(e.target.value);
	};
	// const [showPassword, setShowPassword] = useState(false);
	// const [isSignup, setIsSignup] = useState(false);
	// const dispatch = useDispatch();
	// const navigate = useNavigate();

	// // const isSignup = true;

	// const handleShowPassword = () => setShowPassword(!showPassword);

	// const handleSubmit = (e) => {
	//   e.preventDefault();

	//   console.log("formdata == ", formData)
	//   if (isSignup) {
	//     dispatch(signup(formData, navigate));
	//   } else {
	//     dispatch(signin(formData, navigate));
	//   }
	// }

	// const handleChange = (e) => {
	//   setFormData({ ...formData, [e.target.name]: e.target.value })
	// }

	// const switchMode = () => {
	//   setIsSignup((prevIsSignup) => !prevIsSignup);
	//   setShowPassword(false);
	// }

	// const handleSubmit = (e) => {
	//   console.log(e.password.value);
	//   if (e.email.value === "admin@gmail.com" || e.password.value === "admin") {
	//     console.log(e.password.value);
	//     console.log(e.email.value);
	//   } else {
	//     console.log("Wrong Id and Password");
	//   }
	// };

	const clickHandle = (e) => {
		console.log(e.password.value);
		console.log(e.email.value);
	};

	const registerPage = () => {
		const navigate = useNavigate();
		navigate('/addadmin', <AddAdmin />);
		isLoggedIn(true);
	};

	return (
		<div class="box">
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { m: 1, width: '25ch' }
				}}
				noValidate
				autoComplete="off"
			/>
			<Container component="main" maxWidth="xs">
				<Paper className={classes.paper} elevation={3}>
					<img src={logo} style={{ marginTop: '13px', height: '90px' }} alt="orbitmart merchant" />

					<div style={{ alignItems: 'right', marginTop: 10 }} />
					<div class="justify-end">
						{/* <TextField
              id="outlined-select-currency"
              select
              label="Login For"
              value={panel}
              onChange={handleChange}
            >
              {panels.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}
					</div>
					<Typography variant="h5" />
					<form className={classes.form}>
						<Grid container spacing={2}>
							{/* {
              isSignup && (
                <>
                  <Input 
                    name = "firstname" 
                    label = "First Name" 
                    handleChange = {handleChange} 
                    autoFocus 
                    half 
                  />
                  <Input 
                    name = "lastname" 
                    label = "Last Name" 
                    handleChange = {handleChange}  
                    half 
                  />
                </>
              )
            } */}
							<Input
								name="email"
								label="Email Address"
								type="email"
								value={(e) => email(e.target.value)}
							/>
							<Input
								name="password"
								label="Password"
								type="password"
								value={(e) => password(e.target.value)}
							/>
							{/* { isSignup && 
              <Input 
                name="confirmPassword" 
                label="Repeat Password" 
                handleChange={handleChange} 
                type="password" 
              /> 
            } */}
						</Grid>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={() => clickHandle()}>
							Sign In
						</Button>
						{/* <div style={{ alignContent: "center" }}>New To Panel</div> */}

						<Grid container justify="flex-end">
							<Grid item />
						</Grid>
					</form>
				</Paper>
			</Container>
		</div>
	);
};

export default Auth;
