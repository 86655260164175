import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../view.css";
import axios from "axios";
import Compressor from "compressorjs";
import StateContext from "../../contexts/ContextProvider";
import blank from "../../assets/blank.jpg";
import { BsFillPencilFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { Alert, Stack } from "@mui/material";

const AddCategorys = () => {
  const navigate = useNavigate();

  const { setValue } = useContext(StateContext);

  const initialState = {
    category: "",
  };
  const [addData, setAddData] = useState(initialState);
  const [data, setData] = useState([""]);
  const [types, setTypes] = useState([]);
  const [compressedFile, setCompressedFile] = useState("");
  //consy[(id, setId)] = useState("");

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const mainPage = () => {
    navigate("/category");
  };

  const handleId = (e) => {
    debugger;
    let index = e.target.selectedIndex;
    setData(e.target.childNodes[index].id);
  };

  console.log(data);

  const getTypes = async () => {
    debugger;
    const res = await axios.get(`https://api.orbitmart.co.in/api/v1/merchant/Type`);
    setTypes(res.data.data);
    debugger;
    console.log(res.data.data);
  };

  //ImagePart

  const [file, setFile] = React.useState("");

  const [serverImage, setServerImage] = useState();

  function uploadSingleImage(e) {
    setFile(e.target.files[0]);
  }

  console.log(file.name);

  const ImageThumb = ({ image, icon }) => {
    return (
      <div>
        <img src={URL.createObjectURL(image)} alt={image.name} />
      </div>
    );
  };

  function deleteImage(e) {
    const s = file.filter((item, index) => index != e);
    setFile(s);
    console.log(s);
  }

  // function imageUpload() {
  //   debugger;
  //   const uploadAPI = "https://api.orbitmart.co.in/api/v1/upload";
  //   const formData = new FormData();
  //   const finalImg = file.name;

  //   const article = { image: finalImg };
  //   axios
  //     .post("https://api.orbitmart.co.in/api/v1/upload", article)
  //     .then((res) => {
  //       console.log(res);
  //       console.log(res.files);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       console.log(error.message);
  //     });
  //   // axios
  //   //   .post(uploadAPI, formData)
  //   //   .then((res) => {
  //   //     console.log(res.data);
  //   //     setServerImage(res.data.files);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error.message);
  //   //     console.error("There was an error!", error);
  //   //   });
  // }

  useEffect(() => {
    getTypes();
  }, []);

  console.log("Server Image", serverImage);

  // async function handleSubmit() {
  //   await axios
  //     .post("https://api.orbitmart.co.in/api/v1/category", {
  //       title: addData.category,
  //       merchantTypeId: data,
  //     })
  //     .then((res) => {
  //       alert("its working");
  //     })
  //     .catch((error) => {
  //       alert("its not working");
  //     });
  //   mainPage();
  // }

  function postData() {
if(addData.category==""||data=="" || selectedImages.length === 0)
{
  
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 1000);
}
else{
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = "https://api.orbitmart.co.in/api/v1/category";
    const formData = new FormData();
    const imageFinal = selectedImages[0];
    // new Compressor(imageFinal, {
    //   quality: 0.6,
    //   success: (compressedResult) => {
    //     setCompressedFile(compressedResult);
    //   },
    // });
    formData.append("Image", imageFinal);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        debugger;
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          title: addData.category,
          merchantTypeId: data,
          image: coverPhoto,
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then((res) => {
            console.log(res);
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 1000);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
    }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Add Category
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          class="addButton"
          onClick={postData}
          style={{ width: "8%" }}
        >
          Add
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 2 }}
          >
            <label
              for="domainType"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Domain Type
            </label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="domainType"
              onChange={handleId}
            >
              <option>-- Select Type --</option>
              {types.map((typeget) => (
                <option value={typeget.domainType} id={typeget._id}>
                  {typeget.domainType}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="address"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Category
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="category"
              name="category"
              value={addData.category}
              placeholder="Enter category"
              onChange={handleChange}
            />
          </div>

          <div>
            <label>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 28 }}
          >
            {file.length > 0 &&
              file.map((item, index) => {
                return (
                  <div style={{ display: "inline-block" }} key={item}>
                    <p className="block-icon">
                      <img src={item} alt="img" />
                      <ImCross
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteImage(index)}
                        className="icon-tag"
                      />
                    </p>
                  </div>
                );
              })}
            <input
              disabled={file.length === 1}
              type="file"
              onChange={uploadSingleImage}
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="image"
              name="image"
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            {file && <ImageThumb image={file} />}
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default AddCategorys;
