import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { BsFillPencilFill } from "react-icons/bs";

const gridDeliveryEdit = () => (
  <div className="flex items-center justify-center gap-2">
    <span>
      <Link to="/deliveryrate/edit">
        <BsFillPencilFill />
      </Link>
    </span>
  </div>
);

export const deliveryGrid = [
  {
    field: "id",
    headerText: "Id",
    width: "30",
    textAlign: "Center",
  },
  {
    field: "amountrange",
    headerText: "Amount Range",
    width: "30",
    editType: "dropdownedit",
    textAlign: "Center",
  },
  {
    field: "kmrange",
    headerText: "KM Range",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "amount",
    headerText: "Amount",
    width: "20",
    textAlign: "Center",
  },
  {
    field: "amountemployee",
    headerText: "Amount to Employee",
    width: "50",
    textAlign: "Center",
  },
  {
    field: "action",
    headerText: "Action",
    width: "35",
    textAlign: "Center",
    template: gridDeliveryEdit,
  },
];

export const deliveryData = [
  {
    id: 1,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 2,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 3,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 4,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 5,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 6,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 7,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 8,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 9,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
  {
    id: 10,
    amountrange: 5000,
    kmrange: 3,
    amount: 1000,
    amountemployee: 200,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

const DeliveryRate = () => {
  const editing = { allowDeleting: true, allowEditing: true };
  const navigate = useNavigate();

  const addPage = () => {
    navigate("/deliveryrate/add");
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Delivery" />
      <button
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={() => addPage()}
      >
        New Delivery Rate
      </button>
      <GridComponent
        id="gridcomp"
        dataSource={deliveryData}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {deliveryGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Sort,
            ContextMenu,
            Filter,
            Page,
            ExcelExport,
            Edit,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default DeliveryRate;
