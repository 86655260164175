import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "../view.css";
import axios from "axios";
import { Alert, Stack } from "@mui/material";

const AddClassification = () => {
  const navigate = useNavigate();

  const initialState = {
    classification: "",
  };
  const [addData, setAddData] = useState(initialState);
  const [data, setData] = useState([""]);
  const [types, setTypes] = useState([]);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };

  const mainPage = () => {
    navigate("/classification");
  };
  const handleId = (e) => {
    debugger;
    let index = e.target.selectedIndex;
    setData(e.target.childNodes[index].id);
  };
  console.log(data);

  const getTypes = async () => {
    debugger;
    const res = await axios.get(`https://api.orbitmart.co.in/api/v1/category`);
    setTypes(res.data.data);
    debugger;
    console.log(res.data.data);
  };

  async function handleSubmit() {
    debugger;
    await axios
      .post("https://api.orbitmart.co.in/api/v1/classification", {
        title: addData.classification,
        categoryId: data,
      })
      .then((res) => {
        console.log(res);
        setAlert(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 1000);
      });
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Add Classification
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          class="addButton"
          onClick={handleSubmit}
          style={{ width: "8%" }}
        >
          Add
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 2 }}
          >
            <label
              for="categoryType"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Category Type
            </label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="categories"
              onChange={handleId}
            >
              <option>-- Select Type --</option>
              {types.map((typeget) => (
                <option value={typeget.title} id={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="address"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Classification
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="classification"
              name="classification"
              value={addData.classification}
              placeholder="Enter classification"
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddClassification;
