import React from "react";
import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";

import StateContext from "../../contexts/ContextProvider";

import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
  width: 347,
  height: 220,
};

const ShipPopup = ({props}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {orderData, setOrderData} = useContext(StateContext)
    const API = "https://api.orbitmart.co.in/api/v1/order";
    const getAmount = (itemId) => {
      const subtotal = itemId?.productDetails
        ?.map((pro) => {
          return pro.productId?.itemPrice * pro?.quantity;
        })
        .reduce((a, b) => a + b);
      const customerDiscount = itemId?.productDetails
        ?.map((pr) => {
          if (pr?.productId?.discountType == "FIXED") {
            return parseFloat(pr?.productId?.customerDiscount);
          } else {
            return (
              (parseFloat(pr?.productId?.itemPrice) *
                parseFloat(pr?.quantity) *
                parseFloat(pr?.productId?.customerDiscount)) /
              100
            );
          }
        })
        .reduce((a, b) => a + b);
      const GstLogic =
        ((subtotal - customerDiscount) *
          parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
        100;
      const DeliveryAmount = 0;
      const TotalAmount = (
        subtotal -
        customerDiscount +
        GstLogic +
        DeliveryAmount
      ).toFixed(0);
      return TotalAmount;
    };
    const handleDeliver = async () => {
      const payload = {
        orderStatus: "DELIVERED",
      
      };
    
  
      // add token
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-token": sessionStorage.getItem("myToken"),
        },
      };
      await axios
        .put(`${API}/${props._id}`, payload, config)
        .then(async(response) => {
          const SuccessRes = response.data.status == "SUCCESS";
          if (SuccessRes) {

              await axios
     
              .get(`https://api.orbitmart.co.in/api/v1/order`)
              .then((res) => {
        
                let data=res.data.data
                .filter((or) => or.distributorId == sessionStorage.getItem("id"))
                .reverse().map((itemId)=>{ itemId.grandTotal=getAmount(itemId);return itemId})      
                setOrderData(data);
            
              })
              .catch((err) => {
                console.log("orderdata error",err.message);
              });
            console.log("handleAssign response", response);
            mainPage();
          }
        })
        .catch((error) => {
          console.log("handleAssign response error!", error);
        });
    };
      return (

        <div>
          <button
            type="button"
            style={{ marginTop: 1, alignItems: "center" }}
            // onClick={handleOpen}
          >
            SHIPPED
          </button>
        </div>
      );
}

export default ShipPopup;