import React, { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import StateContext from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import blank from "../../assets/blank.jpg";

const ViewMerchant = () => {
  const { id } = useParams();
  console.log(id);

  const { viewData } = useContext(StateContext);


  // const [viewData, setviewData] = useState(viewData);

  console.log(viewData);
  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  // function fetchData() {
  //   debugger;
  //   const finalId = id;
  //   fetch(`https://api.orbitmart.co.in/api/v1/auth/byDistributor/${finalId}`)
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       setviewData(data.data);
  //     });
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // console.log(viewData);

  const navigate = useNavigate();

  function mainPage() {
    navigate("/merchants");
  }

  return (
    <div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        View Merchant
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{
            alignItems: "center",
            alignContent: "center",
            width: "8%",
          }}
          onClick={() => mainPage()}
        >
          Back
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="firstName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstName"
              name="firstName"
              value={viewData.firstName}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="lastName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastName"
              name="lastName"
              value={viewData.lastName}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="pincode"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Pin Code
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pincode"
              name="pincode"
              value={viewData.pincode}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="address"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Address
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              value={viewData.address}
              disabled
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
              <label
                for="gender"
                class="form-label inline-block mb-2 text-gray-700"
              >
                Gender
              </label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="gender"
                name="gender"
                value={viewData.gender}
                onChange={handleChange}
              />
            </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="firmName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Firm Name
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firmName"
              name="firmName"
              value={viewData.firmName}
              disabled
            />
          </div>

         <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="state"
              class="form-label inline-block mb-2 text-gray-700"
            >
              State
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="state"
              value={viewData.state}
              disabled
            />
          </div>

        <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="email"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              value={viewData.email}
              disabled
            />
          </div>

      <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="country"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Country
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="country"
              value={viewData.country}
              disabled
            />
          </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="district"
              class="form-label inline-block mb-2 text-gray-700"
            >
              District
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="district"
              value={viewData.district}
              disabled
            />
          </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="city"
              class="form-label inline-block mb-2 text-gray-700"
            >
              City
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="city"
              value={viewData.city}
              disabled
            />
          </div>

           <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="addharCard"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Aadhaar Card
            </label>
            <input
              type="number"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="addharCard"
              value={viewData.addharCard}
              disabled
            />
          </div>

       <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="panCard"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Pan Card
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="panCard"
              value={viewData.panCard}
              disabled
            />
          </div>

         <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="mobile"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Mobile No
            </label>
            <input
              type="number"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="mobile"
              value={viewData.mobile}
              disabled
            />
          </div>

              <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="domainType"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Domain Type
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="domainType"
              value={viewData.domainType}
              disabled
            />
          </div>

    <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="distributorId"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Distributor
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="distributorId"
              value={viewData?.distributorId?.firstName}
              disabled
            />
          </div> 

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="classification"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Classification
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="classification"
              value={viewData.classification}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="bankName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Bank Name
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="bankName"
              value={viewData.bankName}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="bankAccountType"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Bank Account Type
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="bankAccountType"
              value={viewData.bankAccountType}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="accountNo"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Account No
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="accountNo"
              value={viewData.accountNo}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="branchName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Branch Name
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="branchName"
              value={viewData.branchName}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="ifscCode"
              class="form-label inline-block mb-2 text-gray-700"
            >
              IFSC Code
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="ifscCode"
              value={viewData.ifscCode}
              disabled
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="accountNo"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Account No
            </label>
            <input
              type="number"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="accountNo"
              value={viewData.accountNo}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="branchName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Branch Name
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="branchName"
              value={viewData.branchName}
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="ifscCode"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Ifsc Code
            </label>
            <input
              type="text"
              class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="ifscCode"
              value={viewData.ifscCode}
              disabled
            />
          </div> */}

          <div style={{ marginLeft: 20 }}>
            <label>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={viewData.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ViewMerchant;
