import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "../view.css";
import { Alert, Stack } from "@mui/material";
import axios from 'axios'
const AddBrandName = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/brandName");
  };

  const [data, setData] = useState("");


  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  async function postData(){
  if(data == ""){
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);
  } else {
   await axios.post("https://api.orbitmart.co.in/api/v1/brandname",{title:data},{headers: {
            "Content-Type": "application/json",
          }}).then((res)=>{
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          }).catch((err)=>{
            setError(true);
             setTimeout(() => {
           setError(false)
            }, 1000);
          })
   }
  //   try {

  //     const finalData = {
  //       title: data,
  //     };
  //     const res = await fetch("https://api.orbitmart.co.in/api/v1/brandname", {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(finalData),
  //     });
  //     console.log(res)
  //     if (!res.ok) {
  //       setError(true);
  //       setTimeout(() => {
  //         mainPage();
  //       }, 1000);
  //       const message = `An error has occured: ${res.status} - ${res.statusText}`;
  //       throw new Error(message);
  //     }
  //     const data = await res.json();
  //     const result = {
  //       status: res.status + "-" + res.statusText,
  //       headers: {
  //         "Content-Type": res.headers.get("Content-Type"),
  //         "Content-Length": res.headers.get("Content-Length"),
  //       },
  //       data: data,
  //     };
  //     console.log(data);
  //     console.log(result);
  //     setAlert(true);
  //     setTimeout(() => {
  //       mainPage();
  //     }, 1000);
  //   } catch (error) {
  //     setError(true);
  //     setTimeout(() => {
  // setError(false)
  //     }, 1000);
  //     console.log(error.message);
  //   }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Add Brand Name
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          class="addButton"
          onClick={postData}
          style={{ width: "8%" }}
        >
          Add
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="groupCategory"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Brand Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="title"
              value={data}
              onChange={(e)=>setData(e.target.value)}
              
              placeholder="Enter Brand Name"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddBrandName;
