import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import {
  MdOutlineCancel,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import logo from "../assets/orbitmart-logo.png";
import NewLogo from "../assets/orbit.png";

import { links, childlinks } from "../assets/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";

const Sidebar = () => {
  const [expand, setExpand] = useState(false);
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const superWhite = "white";

  const superBlue = "#003c7e";

  const lightBlue = "#4d77a5";

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div
      className="h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10"
      style={{ backgroundColor: "#003c7e" }}
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img
                src={NewLogo}
                style={{
                  alignContent: "center",
                  width: "60%",
                  height: "30%",
                  marginLeft: 45,
                }}
                alt="orbitmart Admin"
              />
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <span
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 375,
              marginLeft: 100,
              color: "white",
              fontSize: 25,
              fontFamily: "Helvetica",
            }}
          >
            Admin
          </span>
          <div className="mt-10 ">
            {links.map((item) => (
              <div
                key={item.title}
                style={{ color: "white", fontWeight: "bold" }}
              >
                <p
                  className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? lightBlue : superBlue,
                      color: isActive ? superBlue : superWhite,
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                    <span className="capitalize">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
            <div style={{ color: "white", fontWeight: "bold" }}>
              <div
                className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => {
                  setExpand((pervState) => !pervState);
                }}
              >
                <MdOutlineProductionQuantityLimits style={{ marginLeft: 13 }} />
                <p style={{ marginLeft: 10 }}>Products</p>
                <div style={{ margin: "0 0 0px 25px" }}>
                  {expand === false ? (
                    <MdKeyboardArrowDown
                      style={{ width: "2em", height: "2em" }}
                    />
                  ) : (
                    <MdKeyboardArrowUp
                      style={{ width: "2em", height: "2em" }}
                    />
                  )}
                </div>
              </div>
              {expand &&
                childlinks.map((link) => (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? lightBlue : superBlue,
                      color: isActive ? superBlue : superWhite,
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                    <span className="capitalize">{link.title}</span>
                  </NavLink>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
